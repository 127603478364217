import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { useRecordContext, useUpdate } from 'react-admin';
import AntSwitch from '../AntSwitch';

const VideoShownInput: FC<{ label: string }> = ({ label }) => {
  const { id, shown } = useRecordContext();

  const [checked, setChecked] = useState(shown);
  const [update, { isLoading }] = useUpdate();

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
    update('videos', { id, data: { shown: e.target.checked } });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
      <AntSwitch checked={checked} onChange={handleChange} disabled={isLoading} />
    </Box>
  );
};

export default VideoShownInput;
