import {
    Edit,
    SimpleForm,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRecordContext,
    TextInput, required,
} from 'react-admin';

import Box from '@mui/material/Box';

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const PrayerWallEdit = () => {
  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<CustomToolBar />}>
          <Box width='90%' ml={3}>
            <TextInput validate={required()} sx={{ width: '100%' }} label="Текст молитви" source="text" multiline/>
          </Box>
      </SimpleForm>
    </Edit>
  );
};

export default PrayerWallEdit;
