import React, { useRef, useState } from "react";
import {
  Button,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Box, Typography, Alert, Button as MuiButton } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { httpsCallable } from "firebase/functions";
import {
  NotificationSendRequestDTO,
  sendNotification,
} from "../../../api/notification";
import {
  download,
  storageRef,
  UploadNotificationFile,
} from "../../../lib/firebase";

const NotificationsTab = () => {
  const ImageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [imageError, setImageError] = useState<string>("");

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { height, width } = img;
        if ((width / height).toFixed(2) !== "1.78") {
          setImageError("Додайте зображення з відношенням сторін 16 на 9");
        } else if (
          height < 720 ||
          height > 1080 ||
          width < 1280 ||
          width > 1920
        ) {
          setImageError(
            "Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів",
          );
        } else {
          setImageError("");
          setImage(file);
          setImagePreview(img.src);
        }
      };
    }
  };

  return (
    <SimpleForm
      toolbar={<NotificationsTabToolbar image={image} />}
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "stretch",
        width: "100%",
      }}
    >
      <Box display="flex" flexDirection="row" width="100%">
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {"Розсилка повідомлень"}
        </Typography>
      </Box>

      <TextInput multiline fullWidth label="Заголовок" source="title" />

      <TextInput
        multiline
        fullWidth
        label="Текст повідомлення"
        source="body"
        minRows={3}
      />

      <Box>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={ImageInputRef}
          onChange={handleUpload}
        />
        {imagePreview && (
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "300px",
              objectFit: "contain",
            }}
            src={imagePreview}
            alt="Preview"
          />
        )}

        {imageError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {imageError}
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <MuiButton
            variant="contained"
            sx={{
              background: "#703eff",
              lineHeight: "18px",
              "&:hover": {
                background: "#703eff",
              },
            }}
            onClick={() => {
              if (ImageInputRef.current) {
                ImageInputRef.current.click();
              }
            }}
          >
            Завантажити фото
          </MuiButton>
          <Typography
            sx={{
              fontSize: 14,
              textAlign: "end",
              width: "70%",
              whiteSpace: "pre-line",
            }}
          >
            {
              "Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів"
            }
          </Typography>
        </Box>
      </Box>
    </SimpleForm>
  );
};

const NotificationsTabToolbar = ({ image }: { image: File | null }) => {
  const form = useFormContext();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const handleSendNotification = async () => {
    try {
      const { title, body } = form.getValues();

      let imageUrl = "";
      if (image) {
        const fileName = `notification_${Date.now()}_${image.name}`;
        const snapshot = await UploadNotificationFile(fileName, image);
        imageUrl = await download(storageRef(snapshot.metadata.fullPath));
      }

      const { data: participants } = await dataProvider.getList<any>(
        "registered_participants",
        {
          filter: { eventId: record.id },
          pagination: { page: 1, perPage: 100000 },
          sort: { field: "id", order: "ASC" },
        },
      );

      const userIds = participants.map(
        (participant) => participant.participantId,
      );

      const { data: users } = await dataProvider.getMany<any>("users", {
        ids: userIds,
      });

      const tokens = users
        .filter((user) => user.fcm_token)
        .map((user) => user.fcm_token as string);

      const notificationData: NotificationSendRequestDTO = {
        title,
        body,
        imageUrl,
        tokens,
      };

      await sendNotification(notificationData);
      notify("Повідомлення успішно відправлено", { type: "success" });
    } catch (error) {
      console.error("Error sending notification:", error);
      notify(
        "Помилка при відправці повідомлення: " +
          (error instanceof Error ? error.message : String(error)),
        { type: "error" },
      );
    }
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        type="button"
        label="Відправити повідомлення"
        onClick={handleSendNotification}
      />
    </Toolbar>
  );
};

export default NotificationsTab;
