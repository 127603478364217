import { FC } from 'react';
import { CircularProgress } from '@mui/material';

const Spinner: FC<{ size?: number }> = ({ size }) => (
  <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress size={size} />
  </div>
);

export default Spinner;
