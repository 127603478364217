import { Box, Stack } from '@mui/material'
import {
	BulkDeleteButton,
	CreateButton,
	Datagrid,
	DateField,
	DateInput,
	ExportButton,
	FilterButton,
	FilterForm,
	List,
	ReferenceField,
	TextField,
	TopToolbar,
} from 'react-admin'
import StatusField from '../../components/common/StatusField'

const postFilters = [
	<DateInput label='За датою оновлення' source='updated_at' />,
	<DateInput label='За датою створення' source='created_at' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton className='filter' filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const BulkActionButtons = () => {
	return (
		<Box mb={1}>
			<BulkDeleteButton label='Видалити' />
		</Box>
	)
}

const BanRequestsList = () => {
	return (
		<List
			title='Заявки на відновлення обликового запису'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'DESC' }}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<StatusField label='Статус' />
				<ReferenceField label='Відправник' source='user_id' reference='users'>
					<TextField source='name' />
				</ReferenceField>
				<DateField label='Дата оновлення' source='updated_at' />
				<DateField label='Дата створення' source='created_at' />
			</Datagrid>
		</List>
	)
}

export default BanRequestsList
