import { ApolloProvider } from '@apollo/client';
import { Routes, Route, HashRouter } from 'react-router-dom';

import apolloClient from '../../lib/apollo';
import PrivateRoute from '../../components/auth/PrivateRoute';

import Login from '../../pages/login';

const RootNavigation = () => (
  <HashRouter>
    <Routes>
      <Route path="*" element={<PrivateRoute />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  </HashRouter>
);

const RootComponent = () => (
  <ApolloProvider client={apolloClient}>
    <RootNavigation />
  </ApolloProvider>
);

export default RootComponent;
