import { useEffect, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";

const CoachInput = ({ label }) => {
  const [coachUsers, setCoachUsers] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("users", {})
      .then(({ data }) => {
        const coachUsers = data.filter((el) => el.access_level === "coach");

        const array = [];

        for (const coach of coachUsers) {
          array.push({ id: coach.id, name: coach.name });
        }

        setCoachUsers(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <SelectInput
      sx={{ ml: !label ? 10 : null }}
      label={label ? label : "Лідер"}
      source="coach_id"
      choices={coachUsers}
    />
  );
};

export default CoachInput;
