import {
	REACT_APP_GRAPHQL_API_URL,
	REACT_APP_HASURA_ADMIN_SECRET,
} from '../constants/constants'

export const fetchUser = async (user: any) => {
	const query = JSON.stringify({
		query: `
      query {
        users_by_pk(id: ${user.uid}) {
          id
          name
          email
          phone
          country
          church
          photo {
            photo_url
          }
          created_at
          gender
          access_level
          coach_id
          connect_id
          coach_connect_id
          connect_group_list {
              name
          }
          banned
          role_id
        }
      }
    `,
	})

	try {
		if (REACT_APP_GRAPHQL_API_URL && REACT_APP_HASURA_ADMIN_SECRET) {
			const response = await fetch(REACT_APP_GRAPHQL_API_URL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${await user.getIdToken()}`,
					'x-hasura-admin-secret': REACT_APP_HASURA_ADMIN_SECRET,
				},
				body: query,
			})

			const result = await response.json()
			console.log('result', result)
			return result.data.users_by_pk
		} else throw new Error('URL and SECRET not found')
	} catch (error) {
		console.error('error in fetchUser', error)
	}
}
