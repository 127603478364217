import { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  required,
  Toolbar,
  Button,
  useDataProvider,
  useNotify,
  LinearProgress,
} from "react-admin";

import { useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import { download, fns, storageRef } from "../../lib/firebase";
import { httpsCallable } from "firebase/functions";
import {
  ValidationError,
  emailValidation,
  passwordValidation,
  phoneValidation,
  stringNameValidation,
} from "../../lib/validation";
import CoachInput from "../../components/common/inputs/CoachInput";
import { SelectCoach } from "./UsersList";

const CustomToolbar = () => {
  const form = useFormContext();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const createUserHandler = async () => {
    const check = (arg) => {
      if (arg && arg.length !== 0) {
        throw new ValidationError(arg);
      }
    };

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    };

    try {
      setLoading(true);
      const { email, password, gender, phone, name, country, church } =
        form.getValues();

      check(stringNameValidation("ПІБ", name));
      check(emailValidation("Пошта", email));
      check(phoneValidation("Телефон", phone));
      check(stringNameValidation("Церква", church));
      check(stringNameValidation("Країна", country));
      check(passwordValidation("Пароль", password));

      const registerUser = httpsCallable(fns, "registerUserWithEmail");

      const number = getRandomInt(1, gender === "female" ? 27 : 22);
      const avatar_ref = storageRef(`avatars/default/${number}-${gender}.svg`);
      const avatar_photo = await download(avatar_ref);

      const user = await registerUser({
        email,
        password,
        gender,
        photo: avatar_photo,
        phone,
        name,
        country,
        church,
      });

      notify("Користувача створено!", { type: "success" });
      form.reset();
    } catch (e) {
      if (e instanceof ValidationError) {
        notify(e.message[0], { type: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Toolbar>
      {loading ? (
        <LinearProgress sx={{ width: "100%" }} />
      ) : (
        <Button
          label="Зберегти"
          disabled={loading}
          size="medium"
          onClick={createUserHandler}
        />
      )}
    </Toolbar>
  );
};

const UsersCreate = () => (
  <Create title="Створити нового користувача">
    <SimpleForm toolbar={<CustomToolbar />}>
      <Box display="flex" flexDirection="column" sx={{ ml: 3 }}>
        <Box display="flex" flex="row" width="100%">
          <TextInput label="Пошта" source="email" validate={required()} />
          <TextInput
            sx={{ ml: 5 }}
            label="ПІБ"
            source="name"
            validate={required()}
          />
        </Box>
        <Box display="flex" flex="row" width="100%">
          <PasswordInput
            sx={{ mb: 2 }}
            label="Пароль"
            source="password"
            validate={required()}
          />
        </Box>
        <Box display="flex" flex="row" width="100%">
          <TextInput label="Телефон" source="phone" />
          <SelectInput
            sx={{ ml: 5 }}
            label="Стать"
            source="gender"
            emptyText="Бажаю не вказувати"
            choices={[
              { id: "male", name: "Чоловік" },
              { id: "female", name: "Жінка" },
            ]}
          />
          <CoachInput />
        </Box>
        <Box display="flex" flex="row" width="100%">
          <TextInput label="Країна" source="country" />
          <SelectInput
            sx={{ ml: 5 }}
            validate={required()}
            label="Рівень доступу"
            source="access_level"
            choices={[
              { id: "user", name: "Користувач" },
              { id: "coach", name: "Лідер" },
              { id: "moderator", name: "Модератор" },
            ]}
          />
        </Box>
        <Box display="flex" flex="row" width="100%">
          <TextInput label="Церква" source="church" />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

export default UsersCreate;
