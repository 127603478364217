import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  Button,
  useNotify,
  useCreate,
  required,
  useRedirect
} from 'react-admin';

import Box from '@mui/material/Box';
import CreateMarkerMap from '../../components/common/map/CreateMarkerMap';
import { useFormContext } from 'react-hook-form';
import { Dispatch, FC, SetStateAction, useEffect, useState, Fragment } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { Coordinates, Place } from "../../types/maps";
import { GoogleProvider } from 'leaflet-geosearch'
import LocationSearchInput from "../../components/common/inputs/LocationSearchInput";

const CustomToolBar:FC<{coordinates: Coordinates, address: string}> = ({ coordinates, address }) => {
  const { getValues } = useFormContext();
  const notify = useNotify();
  const [create, { isLoading }] = useCreate();

  const redirect = useRedirect();
  const SaveHandler = () => {
    try {
      const { name, name_en, address_en } = getValues();
      const data = { name, address, name_en, address_en, coordinates: `(${coordinates.join()})` };

      if (!data.name) {
        notify('Введіть назву', { type: 'error' });
        return;
      } else if (!data.address) {
        notify('Введіть адресу', { type: 'error' });
        return;
      } else if (!data.name_en) {
        notify('Введіть назву англійською', { type: 'error' });
        return;
      } else if (!data.address_en) {
        notify('Введіть адресу англійською', { type: 'error' });
        return;
      } else if (!coordinates) {
        notify('Оберіть коордінати', { type: 'error' });
        return;
      }

      create('meetings', {data: data})
      redirect('/meetings');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button size="medium" disabled={isLoading} label="Зберегти" onClick={SaveHandler} />
    </Toolbar>
  )
}

const MapWrapper:FC<{coordinates: Coordinates, setCoordinates: Dispatch<SetStateAction<Coordinates>>}> = ({ coordinates, setCoordinates }) => {
  const { getValues } = useFormContext();
  const { name, address } = getValues();

  return (
    <CreateMarkerMap
      marker={coordinates}
      address={address}
      name={name}
    />
  );
};

const MeetingsCreate = () => {
  const [coordinates, setCoordinates] = useState<Coordinates>([50.44709928278605, 30.550255268885177]);
  const [address, setAddress] = useState<string>('');

  return (
    <Create title="Cтворити зустріч" sx={{ tableLayout: 'fixed' }}>
      <SimpleForm toolbar={<CustomToolBar coordinates={coordinates} address={address}/>}>
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
          <MapWrapper coordinates={coordinates} setCoordinates={(e) => setCoordinates(e)} />
          <Box display="flex" flexDirection="row" sx={{ width: '100%' }} gap={3} mt={2}>
            <TextInput label="Назва" source="name" validate={required()}/>
            {/*<TextInput label="Адреса" source="address" sx={{ width: '50%'}} validate={required()}/>*/}
            <LocationSearchInput
                address={address}
                setAddress={(value: string) => setAddress(value)}
                setCoordinates={(coords: Coordinates) => setCoordinates(coords)}
            />

            <TextField
              disabled
              fullWidth
              label="Координати"
              required
              sx={{ width: '30%'}}
              value={coordinates}
            />
          </Box>
          <Box display="flex" flexDirection="row" sx={{ width: '100%' }} gap={3}>
            <TextInput label="Назва Eng" source="name_en" validate={required()}/>
            <TextInput label="Адреса Eng" source="address_en" sx={{ width: '60%' }} validate={required()}/>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default MeetingsCreate;
