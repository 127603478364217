import { FC, useRef, useState } from 'react'
import {
	BooleanInput,
	Button,
	DateTimeInput,
	Edit,
	ImageField,
	NumberInput,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useGetList,
	useNotify,
	useRecordContext,
	useRedirect,
	useUpdate,
} from 'react-admin'

import {
	Alert,
	FormControl,
	InputLabel,
	MenuItem,
	Button as MuiButton,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import FilterInput from '../../components/common/inputs/FilterInput'
import VideoStatusInput from '../../components/common/inputs/VideoStatusInput'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'
import { IBannerTitles, IButtonText } from './IBannerTitles'

const CustomToolBar: FC<{
	blob: Blob | null
	blobEN: Blob | null
}> = ({ blob, blobEN }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [update, { isLoading }] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const {
				id,
				title,
				description,
				status,
				premiere_at,
				is_banner,
				duration,
				title_en,
				description_en,
				banner_title,
				shown_banner,
				btn_text,
				btn_text_en,
				position,
				banner_title_en,
			} = data
			delete data.id


			const generateBannerString = () => {
				const id = Math.random().toString(36).substring(2, 12)
				return `Banner${id}`
			}

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					data.title.replace(/\s/g, '') || generateBannerString(),
					blob
				)
				data.snippet = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (blobEN) {
				const snapshot = await UploadVideoSnippet(
					data.title_en.replace(/\s/g, '') || generateBannerString(),
					blobEN
				)
				data.snippet_en = await download(storageRef(snapshot.metadata.fullPath))
			}
			
			if (data) {
				await update('videos', { id, data })
				redirect('/videos')
				return
			}

			if (!title) {
				notify('Введіть назву', { type: 'error' })
				return
			} else if (description) {
				notify('Введіть опис', { type: 'error' })
				return
			} else if (status) {
				notify('Введіть статус', { type: 'error' })
				return
			} else if (
				status === 'upcoming' &&
				dayjs().toISOString() === dayjs(premiere_at).toISOString()
			) {
				notify("Введіть дату прем'єри", { type: 'error' })
				return
			} else if (duration) {
				notify('Введіть тривалість відео', { type: 'error' })
				return
			} else if (title_en) {
				notify('Введіть назву англійською', { type: 'error' })
				return
			} else if (description_en) {
				notify('Введіть опис англійською', { type: 'error' })
				return
			} else if (shown_banner && banner_title) {
				notify('Введіть назву банеру українською', { type: 'error' })
				return
			} else if (shown_banner && banner_title_en) {
				notify('Введіть назву банеру англійською', { type: 'error' })
				return
			}

			await update('videos', { id, data })
			redirect('/videos')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const BannerSetup = ({ isBanner }: { isBanner: boolean }) => {
	const { getValues, setValue } = useFormContext()
	const { banner_title, banner_title_en } = getValues()

	const [bannerTitles, setBannerTitles] = useState<IBannerTitles>({
		banner_title,
		banner_title_en,
	})

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<TextField
				sx={{ width: '100%' }}
				label='Банер Укр'
				// required={isBanner}
				value={bannerTitles.banner_title}
				onChange={e => {
					setValue('banner_title', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title: e.target.value })
				}}
			/>
			<TextField
				sx={{ ml: 5, width: '100%' }}
				label='Банер Eng'
				// required={isBanner}
				value={bannerTitles.banner_title_en}
				onChange={e => {
					setValue('banner_title_en', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title_en: e.target.value })
				}}
			/>
		</Box>
	)
}

const ButtonTextSetup = ({ isBanner }: { isBanner: boolean }) => {
	const { getValues, setValue } = useFormContext()
	const { btn_text, btn_text_en } = getValues()

	const [bannerTitles, setBannerTitles] = useState<IButtonText>({
		btn_text,
		btn_text_en,
	})

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<TextField
				sx={{ width: '100%' }}
				// required={isBanner}
				label='Текст кнопки Укр'
				value={bannerTitles.btn_text}
				onChange={e => {
					setValue('btn_text', e.target.value)
					setBannerTitles({ ...bannerTitles, btn_text: e.target.value })
				}}
			/>
			<TextField
				sx={{ ml: 5, width: '100%' }}
				label='Текст кнопки Eng'
				// required={isBanner}
				value={bannerTitles.btn_text_en}
				onChange={e => {
					setValue('btn_text_en', e.target.value)
					setBannerTitles({ ...bannerTitles, btn_text_en: e.target.value })
				}}
			/>
		</Box>
	)
}

const AdditionalFields = ({ isBanner }: { isBanner: boolean }) => {
	const record = useRecordContext()
	const [status, setStatus] = useState(record.status)
	return (
		<Box
			display='flex'
			flex='row'
			width='100%'
			sx={{ ml: 5 }}
			justifyContent='start'
			alignItems='start'
		>
			{!record.is_banner ? (
				<>
					<Box display='flex' flexDirection='column' alignContent='center'>
						<VideoStatusInput onChange={e => setStatus(e.target.value)} />

						{status === 'upcoming' && (
							<DateTimeInput source='premiere_at' label="Дата прем'єри" />
						)}

						<TextInput
							source='duration'
							label='Тривалість'
							validate={required()}
						/>
					</Box>
					<Box display='flex' flexDirection='column' sx={{ ml: 5 }}>
						<FilterInput />
						{(status === 'live' || status === 'upcoming') && (
							<>
								<Tooltip title='Посилання на онлайн фойє' arrow>
									<TextInput source='zoom' label='Онлайн Фойє' />
								</Tooltip>
								<Tooltip
									title='Поява онлайн фойє (за скільки хвилин до ефіру)'
									arrow
								>
									<NumberInput
										source='online_foyer_time'
										label='Час входу (хвилини)'
										min={0}
									/>
								</Tooltip>
							</>
						)}
					</Box>
				</>
			) : null}
		</Box>
	)
}

const NameDescriptionFields = ({ isBanner }: { isBanner: boolean }) => {
	const record = useRecordContext()

	return (
		<>
			{!record.is_banner ? (
				<>
					<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
						<TextInput
							sx={{ width: '70%' }}
							multiline
							label='Назва Укр'
							isRequired={!isBanner}
							// validate={required()}
							source='title'
						/>
						<TextInput
							multiline
							sx={{ ml: 5, width: '100%' }}
							label='Опис Укр'
							source='description'
							isRequired={!isBanner}
							// validate={required()}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
						<TextInput
							sx={{ width: '70%' }}
							multiline
							label='Назва Eng'
							isRequired={!isBanner}
							// validate={required()}
							source='title_en'
						/>
						<TextInput
							multiline
							sx={{ ml: 5, width: '100%' }}
							label='Опис Eng'
							isRequired={!isBanner}
							source='description_en'
							// validate={required()}
						/>
					</Box>
				</>
			) : null}
		</>
	)
}

const PositionSetup = () => {
	const record = useRecordContext()
	const { data, total, isLoading, error } = useGetList('videos')

	const { getValues, setValue } = useFormContext()
	const { position: pos } = getValues()
	const [position, setPosition] = useState(pos)

	const handleChange = (event: SelectChangeEvent) => {
		setPosition(Number(event.target.value))
		setValue('position', event.target.value)
	}

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Позиція</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={position}
					label='Позиція'
					onChange={handleChange}
				>
					{data?.map((p, i) => (
						<MenuItem value={i + 1}>{i + 1}</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

const VideosEdit = () => {
	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobEN, setBlobEN] = useState<Blob | null>(null)
	const [blobError, setBlobError] = useState<string>('')
	const [isBanner, setIsBanner] = useState(false)

	const ImageInputRef = useRef<HTMLInputElement>(null)
	const ImageInputRefEN = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setBlob(event.target.files[0])
			}
		}
	}

	const handleUploadEN = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setBlobEN(event.target.files[0])
			}
		}
	}

	return (
		<Edit title='Редагувати ефір' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} blobEN={blobEN} />}>
				<Box display='flex' flexDirection='column' sx={{ ml: 3, mr: 3 }}>
					<Box display='flex' flex='row' width='100%'>
						<TextInput fullWidth label='URL' source='url' disabled />
					</Box>
					<Box display='flex' flex='row' width='100%' gap={3}>
						<BooleanInput label='Показується' source='shown' />
						<BooleanInput
							onChange={e => setIsBanner(e.target.checked)}
							label='Баннер'
							source='is_banner'
						/>
						<BooleanInput label='Показується на банері' source='shown_banner' />
					</Box>
					<BannerSetup isBanner={isBanner} />
					<PositionSetup />
					<ButtonTextSetup isBanner={isBanner} />
					<Box display='flex' justifyContent='space-between'>
						<Box display='flex' width='100%' sx={{ mt: 3 }} alignItems='center'>
							<input
								type='file'
								accept='image/*'
								style={{ display: 'none' }}
								ref={ImageInputRef}
								onChange={handleUpload}
							/>

							<Box sx={{ width: '70%' }}>
								{blob ? (
									<img
										style={{ width: '100%', height: '100%' }}
										src={URL.createObjectURL(blob)}
										alt={''}
									/>
								) : (
									<ImageField
										sx={{
											'& .RaImageField-image': {
												margin: 0,
												width: '100%',
												height: '100%',
											},
										}}
										title='video-snippet'
										source='snippet'
									/>
								)}

								{blobError ? (
									<Alert severity='error' sx={{ mb: 2 }}>
										{blobError}
									</Alert>
								) : null}

								<Box
									display={'flex'}
									flexDirection={'row'}
									alignItems={'center'}
									justifyContent={'space-between'}
									gap={2}
								>
									<MuiButton
										variant='contained'
										sx={{
											background: '#703eff',
											lineHeight: '18px',
											'&:hover': {
												background: '#703eff',
											},
										}}
										onClick={() => {
											if (ImageInputRef.current) {
												ImageInputRef.current.click()
											}
										}}
									>
										Змінити картинку UA
									</MuiButton>
									<Typography
										sx={{
											fontSize: 14,
											textAlign: 'end',
											width: '70%',
											whiteSpace: 'pre-line',
										}}
									>
										{
											'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
										}
									</Typography>
								</Box>
							</Box>
						</Box>

						{/*Cover EN*/}
						<Box display='flex' width='100%' sx={{ mt: 3 }} alignItems='center'>
							<input
								type='file'
								accept='image/*'
								style={{ display: 'none' }}
								ref={ImageInputRefEN}
								onChange={handleUploadEN}
							/>

							<Box sx={{ width: '70%' }}>
								{blobEN ? (
									<img
										style={{ width: '100%', height: '100%' }}
										src={URL.createObjectURL(blobEN)}
										alt={''}
									/>
								) : (
									<ImageField
										sx={{
											'& .RaImageField-image': {
												margin: 0,
												width: '100%',
												height: '100%',
											},
										}}
										title='video-snippet'
										source='snippet_en'
									/>
								)}

								{blobError && (
									<Alert severity='error' sx={{ mb: 2 }}>
										{blobError}
									</Alert>
								)}

								<Box
									display={'flex'}
									flexDirection={'row'}
									alignItems={'center'}
									justifyContent={'space-between'}
									gap={2}
								>
									<MuiButton
										variant='contained'
										sx={{
											background: '#703eff',
											lineHeight: '18px',
											'&:hover': {
												background: '#703eff',
											},
										}}
										onClick={() => {
											if (ImageInputRefEN.current) {
												ImageInputRefEN.current.click()
											}
										}}
									>
										Змінити картинку EN
									</MuiButton>
									<Typography
										sx={{
											fontSize: 14,
											textAlign: 'end',
											width: '70%',
											whiteSpace: 'pre-line',
										}}
									>
										{
											'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
										}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box>
						{!isBanner ? <AdditionalFields isBanner={isBanner} /> : null}
					</Box>
					<NameDescriptionFields isBanner={isBanner} />
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default VideosEdit
