import { ValidationError } from './validation'

export const validateYouTubeURL = (url: string) => {
	if (url) {
		const regExp =
			/^(https:\/\/www\.|www\.|https:\/\/).*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
		const match = url.match(regExp)
		return match && match[3].length === 11
	}
}

export class YoutubeVideo {
	protected url: string
	protected id: string
	protected title: string
	protected description: string
	protected duration: string
	protected snippet: string
	protected live: string
	protected snippetEN: string

	protected error: string | null = null

	constructor(url: string) {
		this.url = url
		this.id = this.idParser()
		this.title = ''
		this.description = ''
		this.duration = ''
		this.snippet = ''
		this.snippetEN = ''
		this.live = 'none'
	}

	protected idParser = () => {
		const regExp =
			/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
		const match = this.url.match(regExp)
		return match && match[7].length === 11 ? match[7] : ''
	}

	protected durationParser = (duration: string) => {
		const reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/

		const Time = {
			hours: 0,
			minutes: 0,
			seconds: 0,
		}

		if (reptms.test(duration)) {
			const matches = reptms.exec(duration)
			if (matches && matches[1]) Time.hours = Number(matches[1])
			if (matches && matches[2]) Time.minutes = Number(matches[2])
			if (matches && matches[3]) Time.seconds = Number(matches[3])

			return `${
				Time.hours === 0
					? ''
					: `${Time.hours < 10 ? `0${Time.hours}` : `${Time.hours}`}:`
			}${
				Time.minutes === 0
					? '00:'
					: `${Time.minutes < 10 ? `0${Time.minutes}` : `${Time.minutes}`}:`
			}${
				Time.seconds === 0
					? '00'
					: `${Time.seconds < 10 ? `0${Time.seconds}` : `${Time.seconds}`}`
			}`
		}

		return ''
	}

	get getTitle() {
		return this.title
	}

	get getDescription(): string {
		return this.description
	}

	get getDuration(): string {
		return this.duration
	}

	get getSnippet(): string {
		return this.snippet
	}

	get getInstance() {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			duration: this.duration,
			snippet: this.snippet,
            snippetEN: this.snippetEN,
		}
	}

	isLive(): boolean {
		return this.live === 'live'
	}

	async fetchYoutubeData() {
		const url = `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyCS83t3TlP3Dpvc52ZISEkUPU-0CQOCDYw&part=snippet, contentDetails&id=${this.id}`
		const options = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
			},
		}

		const responce = await fetch(url, options)
		const data = await responce.json()

		if (data.items.length === 0) {
			throw new ValidationError('Відео за даним посиланням не знайдено!')
		}

		const { title, description, liveBroadcastContent, thumbnails } =
			data.items[0].snippet
		const { duration } = data.items[0].contentDetails

		this.title = title
		this.description = description
		this.live = liveBroadcastContent

		if (thumbnails.hasOwnProperty('maxres')) {
			this.snippet = thumbnails.maxres.url
			this.snippetEN = thumbnails.maxres.url
		} else {
			this.snippet = thumbnails.medium.url
			this.snippetEN = thumbnails.medium.url
		}

		this.duration = this.durationParser(duration)
	}

	async getBroadcastInfo(broadcastId: string) {
        const options = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
			},
		}
        // maybe need to do this.id instead broadcastId
		const url = `https://www.googleapis.com/youtube/v3/liveBroadcasts?part=snippet,contentDetails&id=${broadcastId}&key=AIzaSyCS83t3TlP3Dpvc52ZISEkUPU-0CQOCDYw`
		const response = await fetch(url, options)
		const data = await response.json()

		const broadcastInfo = data.items[0]
		const liveChatId = broadcastInfo.snippet.liveChatId

		return { broadcastInfo, liveChatId }
	}

	async getChatMessages(liveChatId: string) {
        const options = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
			},
		}
		const url = `https://www.googleapis.com/youtube/v3/liveChat/messages?part=id&liveChatId=${liveChatId}&key=AIzaSyCS83t3TlP3Dpvc52ZISEkUPU-0CQOCDYw`
		const response = await fetch(url, options)
		const data = await response.json()

		const messagesCount = data.items.length

		return { messagesCount }
	}
}
