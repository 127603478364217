import { SelectInput, required } from 'react-admin';

const RequestStatusInput = () => {
  const connectGroupsList = [
    { id: 'pending', name: 'Очікування' },
    { id: 'accepted', name: 'Прийнято' },
    { id: 'rejected', name: 'Відхилено' },
  ];

  return (
    <SelectInput
      label="Статус"
      sx={{ mx: 3 }}
      source="status"
      choices={connectGroupsList}
      validate={required()}
    />
  );
};

export default RequestStatusInput;
