import { Box, Chip, Link, Stack } from '@mui/material'
import {
	BulkDeleteButton,
	Button,
	Datagrid,
	DateField,
	EmailField,
	ExportButton,
	FilterButton,
	FilterForm,
	FunctionField,
	List,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	required,
	useRecordContext,
} from 'react-admin'
import { Link as RouterLink } from 'react-router-dom'

import { useState } from 'react'
import PreferenceButtons from '../../components/common/PreferenceButtons'
import StatusField from '../../components/common/StatusField'
import UpdateStatusButton from '../../components/common/UpdateStatusButton'

const postFilters = [
	<SelectInput
		label='За статусом'
		source='status'
		validate={required()}
		choices={[
			{ id: 'pending', name: 'Очікування' },
			{ id: 'accepted', name: 'Прийнято' },
			{ id: 'rejected', name: 'Відхилено' },
		]}
	/>,
	<TextInput label="За ім'ям" source='name' defaultValue='user' />,
	<TextInput label='За поштою' source='email' />,
	<TextInput label='За телефоном' source='phone' />,
	<TextInput label='За країною' source='country' />,
	<TextInput label='За містом' source='region' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton className='filter' filters={postFilters} />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const BulkActionButtons = () => {
	return (
		<Box mb={1}>
			<UpdateStatusButton status={'accepted'} resource={'volontier_group'} />
			<UpdateStatusButton status={'rejected'} resource={'volontier_group'} />
			<UpdateStatusButton status={'pending'} resource={'volontier_group'} />
			<BulkDeleteButton label='Видалити' />
		</Box>
	)
}

const CountVolTeams = ({ label }: { label: string }) => {
	const { volunteer_teams } = useRecordContext()
	const [expanded, setExpanded] = useState(false)

	const displayedTeams = expanded
		? volunteer_teams
		: volunteer_teams.slice(0, 1)

	const chipStyle = {
		background: '#1976d2',
		borderRadius: 2,
		color: 'white',
		fontWeight: 600,
		mb: 1,
		mr: 1,
	}

	return (
		<FunctionField
			label={label}
			render={() => (
				<>
					{displayedTeams.map((v: any) => (
						<Link component={RouterLink} to={`/volunteer_teams/${v.id}`}>
							<Chip key={v.id} label={v.name} sx={chipStyle} />
						</Link>
					))}

					{volunteer_teams.length > 1 && (
						<Button
							sx={{ mt: 1 }}
							label={expanded ? 'Сховати' : 'Більше'}
							onClick={() => {
								setExpanded(!expanded)
							}}
						/>
					)}
				</>
			)}
		/>
	)
}

const VolontierGroupsList = () => {
	return (
		<List
			title='Заявки до волонтерської групи'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'DESC' }}
		>
			<Datagrid
				// rowClick='edit'
				bulkActionButtons={<BulkActionButtons />}
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<StatusField label='Статус' />
				<CountVolTeams label='К-ть команд' />
				<DateField label='Відправлено' source='created_at' />
				<TextField label='ПІБ' source='name' />
				<EmailField label='Пошта' source='email' />
				<TextField label='Телефон' source='phone' />
				<TextField label='Країна' source='country' />
				<TextField label='Місто' source='region' />
				<TextField label='Таланти' source='talents' />
				<PreferenceButtons label='Налаштування' />
			</Datagrid>
		</List>
	)
}

export default VolontierGroupsList
