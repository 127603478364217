import { Stack } from '@mui/material'
import {
	CreateButton,
	Datagrid,
	DateField,
	DateInput,
	ExportButton,
	FilterButton,
	FilterForm,
	List,
	TextField,
	TextInput,
	TopToolbar,
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'

const postFilters = [
	<TextInput label='За назвою' source='name' />,
	<TextInput label='За адресою' source='address' />,
	<DateInput label='За датою зустрічі' source='date' />,
	<DateInput label='За датою оновлення' source='updated_date' />,
	<DateInput label='За датою створення' source='created_date' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton className='filter' filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)
const RolesNamesList = () => {
	return (
		<List
			title='Назви ролей'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<TextField label='Назва' source='name' />
				<DateField label='Дата створення' source='created_at' />
			</Datagrid>
		</List>
	)
}

export default RolesNamesList
