import { FC, useRef, useState } from 'react'
import {
	BooleanInput,
	Button,
	Create,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreate,
	useNotify,
	useRedirect,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { Alert, Button as MuiButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { download, storageRef, UploadVideoSnippet } from '../../lib/firebase'

const CustomToolBar: FC<{
	blob: Blob | null
}> = ({ blob }) => {
	const form = useFormContext()
	const [create, { isLoading }] = useCreate()

	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const values = form.getValues()
			console.log('values', values)

			let cover

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					values.title.replace(/\s/g, ''),
					blob
				)
				cover = await download(storageRef(snapshot.metadata.fullPath))
			}

			const data = {
				title: values.title,
				title_en: values.title_en,
				cover,
				description: values.description,
				description_en: values.description_en,
				link: values.link,
				shown: values.shown,
				dark_text: values.dark_text,
			}

			await create('foundations', { data })
			redirect('/foundations')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const FoundationsCreate = () => {
	const notify = useNotify()
	const [blob, setBlob] = useState<Blob | null>(null)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setBlob(event.target.files[0])
			}
		}
	}

	return (
		<Create title='Створити новий фонд' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} />}>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Українською'
						source='title'
					/>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Англійською'
						source='title_en'
					/>
				</Box>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Українською'
						source='description'
					/>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Англійською'
						source='description_en'
					/>
				</Box>
				<Box display='flex' flex='row' width='100%'>
					<BooleanInput label='Показується' source='shown' />
					<BooleanInput label='Темний текст' source='dark_text' />
				</Box>
				<Box display='flex' flexDirection='row' width='100%'>
					<TextInput
						fullWidth
						validate={required()}
						label='Link'
						source='link'
					/>
				</Box>

				<Box
					display='flex'
					flex='row'
					width='50%'
					sx={{ mt: 3 }}
					alignItems='center'
					gap={3}
				>
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={ImageInputRef}
							onChange={handleUpload}
						/>
						<img
							style={{ width: '100%', height: '100%' }}
							src={
								blob
									? URL.createObjectURL(blob)
									: 'https://dummyimage.com/hd1080'
							}
							alt='photo'
						/>

						{blobError && (
							<Alert severity='error' sx={{ mb: 2 }}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (ImageInputRef.current) {
										ImageInputRef.current.click()
									}
								}}
							>
								Змінити картинку
							</MuiButton>
							<Typography
								sx={{
									fontSize: 14,
									textAlign: 'end',
									width: '70%',
									whiteSpace: 'pre-line',
								}}
							>
								{
									'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
								}
							</Typography>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default FoundationsCreate
