import { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
} from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../lib/firebase';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { Box } from '@mui/system';
import { AuthContext } from '../../lib/contexts';

interface FormData {
  email: string;
  password: string;
  showPassword: boolean;
}

const Login = () => {
  const { currentUser } = useContext<any>(AuthContext);
  const navigate = useNavigate();

  const [values, setValues] = useState<FormData>({ email: '', password: '', showPassword: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (prop: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const { email, password } = values;
    setIsLoading(true);
    try {
      setIsLoading(false);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('*');
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          height: '96vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant="h4" component="h1" sx={{ mb: 6, fontWeight: 600 }}>
          Login
        </Typography>

        <TextField
          id="email-input"
          label="Email"
          variant="standard"
          sx={{ mb: 2, width: '30ch' }}
          onChange={handleChange('email')}
        />
        <FormControl sx={{ mb: 6, width: '30ch' }} variant="standard">
          <InputLabel htmlFor="password-input">Password</InputLabel>
          <Input
            id="password-input"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end" sx={{ mb: 1 }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          sx={{
            backgroundColor: '#703eff',
            width: '15ch',
            '&:hover': { backgroundColor: '#7c4dff' },
          }}
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading}>
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
