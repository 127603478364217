import { useEffect, useState } from 'react';
import {
    Edit,
    SimpleForm,
    SelectInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRecordContext,
    useDataProvider,
    DateInput,
    TextInput, useUpdate, ReferenceInput, TextField, ReferenceField,
} from 'react-admin';

import Box from '@mui/material/Box';
import RequestStatusInput from '../../components/common/inputs/RequestStatusInput';
import {useFormContext} from "react-hook-form";
import {httpsCallable} from "firebase/functions";
import {fns} from "../../lib/firebase";

const Title = () => {
    const record = useRecordContext();
    return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
    const { group_id, user_id } = useRecordContext();
    const { getValues } = useFormContext();
    const [update] = useUpdate();

    const SecondarySaveHandler = async () => {
        const { group_id: group_id_form, status, user_id: banned_user_id } = getValues();
        if ( group_id !== group_id_form ) {
            await update('users', { id: user_id, data: { connect_id: group_id_form } })
        }

        if ( status === 'accepted' ) {
            const unbanUser = httpsCallable(fns, "unbanUser");
            await unbanUser({uid: banned_user_id});
        }
    }

    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton label="Зберегти" onClick={SecondarySaveHandler}/>
            <DeleteButton label="Видалити" />
        </Toolbar>
    );
};

const BanRequestsEdit = () => {
    return (
        <Edit title={<Title />}>
            <SimpleForm toolbar={<CustomToolBar />}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flex="row" width="100%">
                        <DateInput sx={{ ml: 3 }} label="Відправлено" disabled source="created_at" />
                        <ReferenceInput disabled label="Відправник" source="user_id" reference="users" name={'NAME'}>
                            <TextField source="name" />
                        </ReferenceInput>
                    </Box>
                    <Box display="flex" flex="row" width="100%">
                        <RequestStatusInput />
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default BanRequestsEdit;
