import { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  useDataProvider,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  LinearProgress,
  Button,
  BooleanField,
  BooleanInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";

const CustomToolBar = () => {
  const form = useFormContext();
  const notify = useNotify();
  const [create, { isLoading }] = useCreate();
  const redirect = useRedirect();

  const onSave = async () => {
    try {
      const values = form.getValues();
      await create("roles", { data: values });
      redirect("/roles");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Toolbar>
      {isLoading ? (
        <LinearProgress sx={{ width: "100%" }} />
      ) : (
        <Button
          label="Зберегти"
          disabled={isLoading}
          size="medium"
          onClick={onSave}
        />
      )}
    </Toolbar>
  );
};

const RolesNamesInput = () => {
  const [rolesNames, setRolesNames] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("roles_names", {})
      .then(({ data }) => {
        const array = [];

        for (const el of data) {
          array.push({ id: el.id, name: el.name });
        }

        setRolesNames(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <SelectInput
      sx={{ ml: 3 }}
      label="Назва ролі"
      source="role_name_id"
      choices={rolesNames}
      validate={required()}
    />
  );
};

const TablesNamesInput = () => {
  const [tables, setTables] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("tables_names", {})
      .then(({ data }) => {
        const array = [];

        for (const el of data) {
          let name = "";

          switch (el.name) {
            case "users": {
              name = "Користувачі";
              break;
            }
            case "videos": {
              name = "Ефіри та записи";
              break;
            }
            case "filters": {
              name = "Фільтри";
              break;
            }
            case "meetings": {
              name = "Недільні зустрічі";
              break;
            }
            case "connect_group": {
              name = "Заявки до спільноти";
              break;
            }
            case "connect_group_list": {
              name = "Спільноти";
              break;
            }
            case "volontier_group": {
              name = "Заявки до волонтерської групи";
              break;
            }
            case "prayers": {
              name = "Модерація Молитовної стіни";
              break;
            }
            case "alerts_to_coach": {
              name = "Заявки до лідерів";
              break;
            }
            case "roles": {
              name = "Ролі";
              break;
            }
            case "roles_names": {
              name = "Назви ролей";
              break;
            }
            case "all": {
              name = "Доступ до всіх таблиць";
              break;
            }
            default: {
              name = "Помилка";
              break;
            }
          }
          array.push({ id: el.id, name: name });
        }

        setTables(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <SelectInput
      sx={{ ml: 5 }}
      label="Назва таблиці"
      source="table_id"
      choices={tables}
      validate={required()}
    />
  );
};

export const AccessLevelInput = () => {
  const [accessLevels, setAccessLevels] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("access_level", {})
      .then(({ data }) => {
        const array = [];

        for (const el of data) {
          array.push({ id: el.id, name: el.name });
        }

        setAccessLevels(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <SelectInput
      sx={{ ml: 3 }}
      label="Рівні доступу"
      source="access_level_id"
      choices={accessLevels}
      validate={required()}
    />
  );
};

const RolesCreate = () => {
  return (
    <Create title="Створити роль" sx={{ tableLayout: "fixed" }}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="row">
          <RolesNamesInput />
          <TablesNamesInput />
          <AccessLevelInput />
        </Box>
        <Box sx={{ ml: 3 }}>
          <BooleanInput source="is_default" label="Роль по замовчуванню" />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default RolesCreate;
