import {
	BooleanInput,
	Button,
	Create,
	ImageField,
	ImageInput,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreate,
	useGetList,
	useNotify,
	useRecordContext,
	useRedirect,
} from 'react-admin'

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
	DownloadIconUrl,
	storageRef,
	UploadFilterIcon,
} from '../../lib/firebase'

const Title = () => {
	const record = useRecordContext()
	return <span> {record?.name} </span>
}

const CustomToolBar = () => {
	const { getValues } = useFormContext()
	const [create, { isLoading }] = useCreate()
	const notify = useNotify()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		const {
			type,
			index,
			filter_icon,
			name_ua,
			name_en,
			is_icon_shown,
			is_access_unregister_user,
			position,
		} = getValues()

		if (!type) {
			notify('Введіть тип', { type: 'error' })
			return
		} else if (!name_ua) {
			notify('Введіть назву українською', { type: 'error' })
			return
		} else if (!name_en) {
			notify('Введіть назву англійською', { type: 'error' })
			return
		} else if (!filter_icon && is_icon_shown) {
			notify('Оберіть іконку або вимкніть показ іконки', { type: 'error' })
			return
		}
		const UploadIcon = async () => {
			const snapshot = await UploadFilterIcon(
				`${type}.svg`,
				filter_icon.rawFile
			)
			return await DownloadIconUrl(storageRef(snapshot.metadata.fullPath))
		}

		const data = {
			index: 1,
			type,
			is_access_unregister_user,
			name_ua,
			name_en,
			is_icon_shown,
			position,
			filter_icon: await UploadIcon(),
		}

		await create('filters', { data })
		redirect('/filters')
	}

}

const FiltersCreate = () => {
  return (
    <Create title={<Title />}>
		{/* @ts-ignore */}
      <SimpleForm toolbar={<CustomToolBar/>}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box sx={{ p: 2, mb: 2, background: '#f5f5f5' }}>
            <span>
              <b> Пояснення: </b> називати тип фільтра треба англійською, символ пробіл змінювати на
              тире або знак мінус. <br /> <br />
            </span>
            <span>
              <em> Приклад: </em> example-filter
            </span>
          </Box>
          <TextInput label="Тип фільтру" source="type" validate={required()} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <TextInput
              label="Назва Укр"
              source="name_ua"
              validate={required()}
              sx={{ mr: 1 }}
              fullWidth
            />
            <TextInput
              label="Назва Eng"
              source="name_en"
              validate={required()}
              sx={{ ml: 1 }}
              fullWidth
            />
          </Box>
          <Box display="flex" alignItems='flex-start' flexDirection="row" justifyContent="space-start"
            gap={3}
          >
            {/* <NumberInput label="Порядок фільтру" source="index" /> */}
            <BooleanInput label="Показувати іконку" source="is_icon_shown" />
            <BooleanInput source="is_access_unregister_user"
              label='Доступно для незареєстрованих користувачів'
            />
          </Box>
          <PositionSetup />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <ImageInput
              source="filter_icon"
              label="Налаштування іконки ( приймається тільки svg формат )"
              accept=".svg"
              placeholder={<p>Перетягніть свою іконку сюди</p>}
              sx={{
                color: 'gray',
                fontSize: 20,
                '& .RaFileInput-dropZone': {
                  backgroundColor: '#f5f5f5',
                  borderBottom: '1px solid gray',
                  borderRadius: 0,
                  color: 'gray'
                },
                "& .RaFileInput-removeButton": {
                  backgroundColor: '#f5f5f5',
                  borderRadius: 0,
                }
              }}
            >
              <ImageField source="src" title="Передпрогляд" />
            </ImageInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};


const PositionSetup = () => {
  const record = useRecordContext()
  const { data, total, isLoading, error } = useGetList('videos')

  const { getValues, setValue } = useFormContext()
  const { position: pos } = getValues()
  const [position, setPosition] = useState(pos)

  const handleChange = (event: SelectChangeEvent) => {
    setPosition(Number(event.target.value))
    setValue('position', event.target.value)
  }

  return (
    <Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Позиція</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={position}
          label='Позиція'
          onChange={handleChange}
        >
          {data?.map((p, i) => (
            <MenuItem value={i + 1}>{i + 1}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FiltersCreate;
