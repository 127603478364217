import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from "apollo-link-context";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { auth } from './firebase';
import {
    REACT_APP_GRAPHQL_API_URL,
    REACT_APP_GRAPHQL_WS_API_URL,
    REACT_APP_HASURA_ADMIN_SECRET
} from "../constants/constants";

const asyncAuthLink = setContext(async () => {
  const user = auth.currentUser;
  
  if (user) {
    const token = await user?.getIdToken();

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-hasura-admin-secret': REACT_APP_HASURA_ADMIN_SECRET,
      },
    };
  }
});

const httpLink = new HttpLink({
  uri: REACT_APP_GRAPHQL_API_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_GRAPHQL_WS_API_URL,
    lazy: true,
    connectionParams: async () => ({
      headers: {
        Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        'x-hasura-admin-secret': REACT_APP_HASURA_ADMIN_SECRET,
      },
    }),
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  asyncAuthLink.concat(httpLink),
);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default apolloClient;
