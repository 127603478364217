import { AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const adminpage = () => {
  history.push('/'); // redirect function to homepage
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    if (username === 'user' && password === 'password') {
      localStorage.setItem('role', 'user');
      localStorage.removeItem('not_authenticated');
      adminpage();
      return Promise.resolve();
    }
    //admin  role   username and password
    if (username === 'admin' && password === 'password') {
      localStorage.setItem('role', 'admin');
      localStorage.removeItem('not_authenticated');
      return Promise.resolve();
    }
    localStorage.setItem('not_authenticated', true);
    return Promise.reject();
  }
  if (type === AUTH_LOGOUT) {
    // localStorage.setItem('not_authenticated', true);
    // localStorage.removeItem('role');
    adminpage();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    return status === 401 || status === 403 ? Promise.reject() : Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('not_authenticated') ? Promise.reject() : Promise.resolve();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    return Promise.resolve(role);
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject('Unknown method');
};