import FmdGoodIcon from '@mui/icons-material/FmdGood'
import { Button, Stack } from '@mui/material'
import { FC } from 'react'
import {
	CreateButton,
	Datagrid,
	DateField,
	DateInput,
	ExportButton,
	FilterButton,
	FilterForm,
	List,
	TextField,
	TextInput,
	TopToolbar,
	useRecordContext,
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'

const postFilters = [
	<TextInput label='За назвою' source='name' />,
	<TextInput label='За адресою' source='address' />,
	// <DateInput label="За датою зустрічі" source="date" />,
	<DateInput label='За датою оновлення' source='updated_date' />,
	<DateInput label='За датою створення' source='created_date' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton className='filter' filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const MapPosition: FC<{ label: string }> = ({ label }) => {
	const { coordinates } = useRecordContext()

	const url = `https://www.google.com.ua/maps/search/${coordinates.substring(
		1,
		coordinates.length - 1
	)}`

	return (
		<Button
			variant='text'
			sx={{ borderRadius: 6 }}
			onClick={() => {
				window.open(url)
			}}
		>
			<FmdGoodIcon />
		</Button>
	)
}

const MeetingsList = () => {
	return (
		<List
			title='Недільні зустрічі'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<TextField label='Назва' source='name' />
				<TextField label='Адреса' source='address' />
				{/*<DateField label="Дата зустрічі" source="date" showTime />*/}
				<MapPosition label='Карта' />
				<DateField label='Оновлено' source='updated_at' />
				<DateField label='Дата створення' source='created_at' />
			</Datagrid>
		</List>
	)
}

export default MeetingsList
