import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import './styles.css';
import {REACT_APP_MAPS_KEY} from "../../../constants/constants";
const LocationSearchInput = ({address, setAddress, setCoordinates}) => {

    useEffect(() => {
        const getCoords = async () => {
            if ( address ) {
                const results = await geocodeByAddress(address);
                const coords = await getLatLng(results[0]);
                if ( coords ) {
                    setCoordinates([coords.lat, coords.lng])
                }
            }
        }
        getCoords();
    }, [address]);

    return (
        <div style={{ width: '50%', height: 48, zIndex: 100000, marginTop: 8}}>
            <PlacesAutocomplete
                apiKey={REACT_APP_MAPS_KEY}
                ApiOptions={{
                    language: 'uk',
                    region: 'UA'
                }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['UA'],
                    }
                }}
                debounce={1000}
                selectProps={{
                    address,
                    onChange: (value) => setAddress(value.label)
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <TextField
                            {...getInputProps({
                                label: 'Шукати...',
                                variant: 'filled',
                                fullWidth: true
                            })}
                        />
                        <div>
                            {loading && <div>Завантаження...</div>}
                            {suggestions.map((suggestion) => {
                                const { description } = suggestion;
                                return (
                                    <div {...getSuggestionItemProps(suggestion)}>
                                        <span>{description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
}

export default LocationSearchInput;