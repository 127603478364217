import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.css'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import {
	MapContainer,
	Marker,
	Popup,
	TileLayer,
	useMapEvent,
} from 'react-leaflet'

const attribution =
	'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
const url =
	'https://tile.jawg.io/jawg-streets/{z}/{x}/{y}.png?lang=en&access-token=73bhnamFwqO8Qm0oCTE9pcjj4NT9kFbFyhJVKN5vPZsPne7R16KPWyq5xI6JMOm1'

const EditMarkerMap = ({ marker, address, name }) => {
	const MapRef = useRef(null)
	const markerRef = useRef(null)

	const [defaultZoom, setDefaultZoom] = useState(13)
	const [coordinates, setCoordinates] = useState(
		marker.replaceAll(/[\(\)]/g, '').split(',')
	)

	useEffect(() => {
		const getCoords = async () => {
			if (address) {
				const results = await geocodeByAddress(address)
				const coords = await getLatLng(results[0])
				console.log('coords', coords)
				if (coords) {
					setCoordinates([coords.lat, coords.lng])
					if (MapRef.current) {
						MapRef.current.flyTo(coords, defaultZoom)
					}
				}
			}
		}
		getCoords()
	}, [address, defaultZoom])

	const markerIconConst = L.icon({
		iconUrl: '/imgs/ico/hillsong-marker.svg',
		iconRetinaUrl: '/imgs/ico/hillsong-marker.svg',
		iconAnchor: [5, 55],
		popupAnchor: [27, -60],
		iconSize: [64, 64],
	})

	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current
				if (marker != null) {
					const { lat, lng } = marker.getLatLng()
					const coordArray = [lat, lng]
					setCoordinates(coordArray)
				}
			},
		}),
		[]
	)

	const Controls = () => {
		const map = useMapEvent({
			locationfound(e) {
				map.flyTo(e.latlng, map.getZoom())
			},
		})

		map.doubleClickZoom.disable()

		return (
			<div className={styles.controlsContainer}>
				<div
					className={styles.currentPosition}
					onClick={() => {
						MapRef.current.locate()
					}}
				>
					<img
						style={{ height: 20, width: 20 }}
						src={'/imgs/ico/navigation.svg'}
						alt={'hillsong-navigation'}
					/>
				</div>

				<div
					className={styles.zoomInButton}
					onClick={() => {
						const currentZoom = MapRef.current.getZoom()
						setDefaultZoom(currentZoom + 1)
						MapRef.current.setZoom(currentZoom + 1)
					}}
				>
					<img
						style={{ height: 20, width: 20 }}
						src={'/imgs/ico/plus.svg'}
						alt={'hillsong-plus'}
					/>
				</div>

				<div
					className={styles.zoomOutButton}
					onClick={() => {
						const currentZoom = MapRef.current.getZoom()
						setDefaultZoom(currentZoom - 1)
						MapRef.current.setZoom(currentZoom - 1)
					}}
				>
					<img
						style={{ height: 20, width: 20 }}
						src={'/imgs/ico/minus.svg'}
						alt={'hillsong-plus'}
					/>
				</div>
			</div>
		)
	}

	const CustomPopup = ({ name, address }) => {
		const blockStyle = {
			height: 24,
			width: '100%',
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 6,
		}

		return (
			<Popup>
				<div style={{ width: 250, height: 100 }}>
					<h1
						className={styles.elementText}
						style={{ color: '#4F5256', fontSize: 18, marginBottom: 20 }}
					>
						{name}
					</h1>
					<div style={blockStyle}>
						<img
							style={{ height: 24, width: 24, marginRight: 6 }}
							src={'/imgs/ico/location-blue.svg'}
							alt={'location'}
						/>
						<p className={styles.elementText} style={{ color: '#4F5256' }}>
							{address}
						</p>
					</div>
				</div>
			</Popup>
		)
	}

	return (
		<div style={{ height: '60vh', width: '100%' }}>
			<MapContainer
				ref={MapRef}
				center={coordinates}
				zoom={defaultZoom}
				minZoom={4}
				zoomControl={false}
				style={{ height: '100%', width: '100%' }}
			>
				<TileLayer url={url} attribution={attribution} />

				<Controls />

				<Marker
					draggable={false}
					ref={markerRef}
					eventHandlers={eventHandlers}
					position={coordinates}
					icon={markerIconConst}
				>
					<CustomPopup name={name} address={address} />
				</Marker>
			</MapContainer>
		</div>
	)
}

export default EditMarkerMap
