import axios from "axios";
import { z } from "zod";

const NotificationSendSchema = z.object({
  title: z.string().trim().max(255),
  body: z.string().trim().max(1000),
  imageUrl: z.string(),
  tokens: z
    .array(z.string().trim().max(255))
    .min(1, "At least one token is required"),
});

export type NotificationSendRequestDTO = z.infer<typeof NotificationSendSchema>;

export async function sendNotification(data: NotificationSendRequestDTO) {
  try {
    NotificationSendSchema.parse(data);

    const response = await axios.post(
      "https://api.churchonline.com.ua/v1/notifications/send",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    console.log("Notification sent successfully:", response.data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error("Validation error:", error.errors);
    } else {
      console.error("Error sending notification:", error);
    }
  }
}
