import { Stack } from "@mui/material";
import { FC, useState } from "react";
import {
  Button,
  CreateButton,
  Datagrid,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  FunctionField,
  ImageField,
  List,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useRecordContext,
} from "react-admin";

import { Box } from "@mui/system";
import BulkActionButtons from "../../components/common/BulkActionButtons";
import PreferenceButtons from "../../components/common/PreferenceButtons";

const postFilters = [
  <TextInput label="За посиланням" source="url" />,
  <TextInput label="За назвою" source="title" />,
  <TextInput label="За описом" source="description" />,
  <TextInput label="За тривалістю" source="duration" />,
  <DateInput label="За датою створення" source="created_date" />,
  <SelectInput
    label="За показом"
    source="shown"
    validate={required()}
    choices={[
      { id: true, name: "Показується" },
      { id: false, name: "Не показується" },
    ]}
  />,
  <SelectInput
    label="За статусом"
    source="status"
    validate={required()}
    choices={[
      { id: "archive", name: "Запис" },
      { id: "upcoming", name: "Заплановано" },
      { id: "live", name: "Эфір" },
    ]}
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton className="filter" filters={postFilters} />
    <CreateButton label="Створити" />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);

const DescriptionField: FC<{ label: string }> = ({ label }) => {
  const { description } = useRecordContext();
  const [text, setText] = useState(
    description.length < 100 ? description : `${description.slice(0, 100)}...`,
  );
  const [pressed, setPressed] = useState(false);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display="flex" flexDirection="column">
          <span> {text} </span>
          {description.length >= 100 && (
            <Box display="flex" alignItems="flex-start">
              <Button
                sx={{ mt: 1 }}
                label={pressed ? "Сховати" : "Більше"}
                onClick={() => {
                  setPressed(!pressed);
                  setText(
                    pressed ? `${description.slice(0, 100)}...` : description,
                  );
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}

const DescriptionFieldEn: FC<{ label: string }> = ({ label }) => {
  const { description_en } = useRecordContext()
  const [text, setText] = useState(
    description_en.length < 100
      ? description_en
      : `${description_en.slice(0, 100)}...`
  )
  const [pressed, setPressed] = useState(false)

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display='flex' flexDirection='column'>
          <span> {text} </span>
          {description_en.length >= 100 && (
            <Box display='flex' alignItems='flex-start'>
              <Button
                sx={{ mt: 1 }}
                label={pressed ? 'Сховати' : 'Більше'}
                onClick={() => {
                  setPressed(!pressed)
                  setText(
                    pressed
                      ? `${description_en.slice(0, 100)}...`
                      : description_en
                  )
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  );
};

export const CoversField: FC<{ label: string }> = ({ label }) => {
  const { covers_description } = useRecordContext();
  const [img, setImg] = useState(covers_description[0]);
  const [pressed, setPressed] = useState(false);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display="flex" flexDirection="column">
          {pressed ? (
            covers_description.map((img: string) => (
              <img
                style={{ width: "auto", height: "100px", marginBottom: 10 }}
                src={img}
                alt="Фото для опису команд"
              />
            ))
          ) : img ? (
            <img
              style={{ width: "auto", height: "100px" }}
              src={img}
              alt="Фото для опису команд"
            />
          ) : (
            ""
          )}
          {covers_description.length >= 2 && (
            <Box display="flex" alignItems="flex-start">
              <Button
                sx={{ mt: 1 }}
                label={pressed ? "Сховати" : "Більше"}
                onClick={() => {
                  setPressed(!pressed);
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  );
};

const VolunteerTeamsList = () => {
  return (
    <List
      title="Команди"
      sx={{ tableLayout: "fixed" }}
      actions={<ListActions />}
      filters={<TagFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons />}
        sx={{
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <TextField label="Назва" source="name" />
        <TextField label="Назва En" source="name_en" />
        <DescriptionField label="Опис" />
        <DescriptionFieldEn label="Опис En" />
        <ImageField label="Лого" source="cover_small" />
        <ImageField label="Лого En" source="cover_small_en" />
        {/* <ImageField label='Фото' source='cover_large' /> */}
        {/* <ImageField label='Фото En' source='cover_large_en' /> */}
        <CoversField label="Фото для опису команд" />
        <PreferenceButtons label="Налаштування" />
      </Datagrid>
    </List>
  );
};

export default VolunteerTeamsList;
