import {Create, required, SaveButton, SimpleForm, TextInput, Toolbar} from "react-admin";
import Box from "@mui/material/Box";

const CustomToolBar = () => (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton label="Зберегти" />
    </Toolbar>
);
const RolesNamesCreate = () => {
    return (
        <Create title="Cтворити назву ролі" sx={{ tableLayout: 'fixed' }}>
            <SimpleForm toolbar={<CustomToolBar />}>
                <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                    <TextInput label="Назва" source="name" validate={required()}/>
                </Box>
            </SimpleForm>
        </Create>
    )
}

export default RolesNamesCreate;