import { Chip, Link } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import {
	DateInput,
	DeleteButton,
	Edit,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useRecordContext,
} from 'react-admin'
import { Link as RouterLink } from 'react-router-dom'
import RequestStatusInput from '../../components/common/inputs/RequestStatusInput'

export const CustomToolbar = props => (
	<Toolbar {...props}>
		<SaveButton variant='contained' color='secondary' />
	</Toolbar>
)

const Title = () => {
	const record = useRecordContext()
	return <span> {record?.name} </span>
}

const CustomToolBar = () => {
	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<SaveButton label='Зберегти' />
			<DeleteButton label='Видалити' />
		</Toolbar>
	)
}

const ConnectGroupInput = () => {
	const [teams, setTeams] = useState([])
	// const dataProvider = useDataProvider();
	const { volunteer_teams } = useRecordContext()

	useEffect(() => {
		const array = []

		for (const element of volunteer_teams) {
			array.push({ id: element.id, name: element.name })
		}

		setTeams(array)
	}, [])

	return (
		<>
			<h3>Назви команд:</h3>
			<Box
				display='flex'
				gap={2}
				flexWrap='wrap'
				flexDirection='row'
				width='50%'
			>
				{teams.map(t => (
					<Link component={RouterLink} to={`/volunteer_teams/${t.id}`}>
						<Chip
							label={t.name}
							sx={{
								background: '#1976d2',
								borderRadius: 2,
								color: 'white',
								fontWeight: 600,
								transition: 'all .3s ease',
								'&:hover': {
									background: '#2000d2',
								},
							}}
						/>
					</Link>
				))}
			</Box>
		</>
	)
}

const VolontierEdit = () => {
	return (
		<Edit title={<Title />}>
			<SimpleForm toolbar={<CustomToolBar />}>
				<Box display='flex' flexDirection='column'>
					<Box display='flex' flex='row' width='100%'>
						<DateInput
							sx={{ ml: 3 }}
							label='Відправлено'
							disabled
							source='created_at'
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput sx={{ ml: 3 }} label='ПІБ' disabled source='name' />
						<TextInput sx={{ ml: 3 }} label='Пошта' disabled source='email' />
						<TextInput sx={{ ml: 3 }} label='Телефон' disabled source='phone' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput
							sx={{ ml: 3 }}
							label='Країна'
							disabled
							source='country'
						/>
						<TextInput sx={{ ml: 3 }} label='Місто' disabled source='region' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput
							sx={{ ml: 3 }}
							label='Таланти'
							multiline
							disabled
							source='talents'
						/>
					</Box>
					<Box display='flex' flexDirection='row' width='100%'>
						<RequestStatusInput />
					</Box>
					{/* <Box
						display='flex'
						flexWrap='wrap'
						flexDirection='column'
						width='80%'
						sx={{ ml: 3 }}
					>
						<ConnectGroupInput />
					</Box> */}
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default VolontierEdit
