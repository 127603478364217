import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC, Fragment, useRef, useState } from 'react'
import {
	BooleanInput,
	Button,
	Create,
	DateTimeInput,
	NumberInput,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreate,
	useGetList,
	useNotify,
	useRecordContext,
	useRedirect,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { Alert, Button as MuiButton, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { validateYouTubeURL, YoutubeVideo } from '../../lib/youtubeParser'

import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { httpsCallable } from 'firebase/functions'
import FilterInput from '../../components/common/inputs/FilterInput'
import VideoStatusInput from '../../components/common/inputs/VideoStatusInput'
import {
	createAnanalytics,
	download,
	fns,
	storageRef,
	UploadVideoSnippet,
} from '../../lib/firebase'
import { ValidationError } from '../../lib/validation'
import { IBannerTitles, IButtonText } from './IBannerTitles'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 400,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))

const CustomToolBar: FC<{ video: any }> = ({ video }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [create, { isLoading }] = useCreate(undefined, undefined, {
		onSuccess: async data => {
			const createScheduledEvent = httpsCallable(fns, 'createScheduledEvent')

			if (data.id) {
				await createAnanalytics(data.id)
			}

			if (data.status === 'upcoming') {
				const duration_array = data.duration.split(':')

				let timeForArchiving = dayjs(data.premiere_at)

				if (duration_array.length === 3) {
					// its have hours, minutes and seconds
					timeForArchiving = dayjs(data.premiere_at)
						.add(Number(duration_array[0]), 'hours')
						.add(Number(duration_array[1]), 'minutes')
						.add(Number(duration_array[2]), 'seconds')
				}

				if (duration_array.length === 2) {
					// its have only minutes and seconds
					timeForArchiving = dayjs(data.premiere_at)
						.add(Number(duration_array[0]), 'minutes')
						.add(Number(duration_array[1]), 'seconds')
				}

				await createScheduledEvent({
					video_id: data.id,
					status: 'live',
					schedule_at: dayjs(data.premiere_at).toISOString(),
				})

				await createScheduledEvent({
					video_id: data.id,
					status: 'archive',
					schedule_at: timeForArchiving.toISOString(),
				})
			} else if (data.status === 'live') {
				const duration_array = data.duration.split(':')

				let timeForArchiving = dayjs(data.created_at)

				if (duration_array.length === 3) {
					// its have hours, minutes and seconds
					timeForArchiving = dayjs(data.created_at)
						.add(Number(duration_array[0]), 'hours')
						.add(Number(duration_array[1]), 'minutes')
						.add(Number(duration_array[2]), 'seconds')
				}

				if (duration_array.length === 2) {
					// its have only minutes and seconds
					timeForArchiving = dayjs(data.created_at)
						.add(Number(duration_array[0]), 'minutes')
						.add(Number(duration_array[1]), 'seconds')
				}

				await createScheduledEvent({
					video_id: data.id,
					status: 'archive',
					schedule_at: timeForArchiving.toISOString(),
				})
			}
		},
	})

	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const values = form.getValues()
			let snippet = video.snippet
			let snippetEN = video.snippetEN

			if (video.snippetBlob) {
				const snapshot = await UploadVideoSnippet(
					(values.banner_title || video.title).replace(/\s/g, ''),
					video.snippetBlob
				)
				snippet = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (video.snippetBlobEN) {
				const snapshot = await UploadVideoSnippet(
					(values.banner_title_en || values.title_en).replace(/\s/g, ''),
					video.snippetBlobEN
				)
				snippetEN = await download(storageRef(snapshot.metadata.fullPath))
			}

			const data = {
				url: video.url,
				title: video.title,
				description: video.description,
				duration: video.duration,
				snippet,
				snippet_en: snippetEN,
				status: values.is_banner ? 'banner' : values.status,
				filter_id: values.filter_id ?? null,
				premiere_at: dayjs(values.premiere_at).toISOString() ?? null,
				zoom: values.zoom ?? null,
				online_foyer_time: values.online_foyer_time ?? 0,
				shown: values.shown,
				title_en: values.title_en,
				description_en: values.description_en,
				banner_title: values.banner_title ?? null,
				banner_title_en: values.banner_title_en ?? null,
				is_banner: values.is_banner,
				btn_text: values.btn_text,
				btn_text_en: values.btn_text_en,
				position: values.position,
			}

			if (data.is_banner) {
				await create('videos', { data })
				redirect('/videos')
				return
			}

			if (!data.title) {
				notify('Введіть назву', { type: 'error' })
				return
			} else if (!data.description) {
				notify('Введіть опис', { type: 'error' })
				return
			} else if (!data.status) {
				notify('Введіть статус', { type: 'error' })
				return
			} else if (
				data.status === 'upcoming' &&
				dayjs().toISOString() === dayjs(data.premiere_at).toISOString()
			) {
				notify("Введіть дату прем'єри", { type: 'error' })
				return
			} else if (!data.duration) {
				notify('Введіть тривалість відео', { type: 'error' })
				return
			} else if (!data.title_en) {
				notify('Введіть назву англійською', { type: 'error' })
				return
			} else if (!data.description_en) {
				notify('Введіть опис англійською', { type: 'error' })
				return
			}

			await create('videos', { data })
			redirect('/videos')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const BannerSetup = ({ isBanner }: { isBanner: boolean }) => {
	const { getValues, setValue } = useFormContext()
	const { banner_title, banner_title_en } = getValues()

	const [bannerTitles, setBannerTitles] = useState<IBannerTitles>({
		banner_title,
		banner_title_en,
	})

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<TextField
				sx={{ width: '100%' }}
				// required={isBanner}
				label='Банер Укр'
				value={bannerTitles.banner_title}
				onChange={e => {
					setValue('banner_title', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title: e.target.value })
				}}
			/>
			<TextField
				sx={{ ml: 5, width: '100%' }}
				label='Банер Eng'
				// required={isBanner}
				value={bannerTitles.banner_title_en}
				onChange={e => {
					setValue('banner_title_en', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title_en: e.target.value })
				}}
			/>
		</Box>
	)
}

const ButtonTextSetuo = ({ isBanner }: { isBanner: boolean }) => {
	const { getValues, setValue } = useFormContext()
	const { btn_text, btn_text_en } = getValues()

	const [bannerTitles, setBannerTitles] = useState<IButtonText>({
		btn_text,
		btn_text_en,
	})

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<TextField
				sx={{ width: '100%' }}
				// required={isBanner}
				label='Текст кнопки Укр'
				value={bannerTitles.btn_text}
				onChange={e => {
					setValue('btn_text', e.target.value)
					setBannerTitles({ ...bannerTitles, btn_text: e.target.value })
				}}
			/>
			<TextField
				sx={{ ml: 5, width: '100%' }}
				label='Текст кнопки Eng'
				// required={isBanner}
				value={bannerTitles.btn_text_en}
				onChange={e => {
					setValue('btn_text_en', e.target.value)
					setBannerTitles({ ...bannerTitles, btn_text_en: e.target.value })
				}}
			/>
		</Box>
	)
}

const PositionSetup = () => {
	const record = useRecordContext()
	const { data, total, isLoading, error } = useGetList('videos')

	const { getValues, setValue } = useFormContext()
	const { position: pos } = getValues()
	const [position, setPosition] = useState(0)

	const handleChange = (event: SelectChangeEvent) => {
		setPosition(Number(event.target.value))
		setValue('position', event.target.value)
	}

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Позиція</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={String(position)}
					label='Позиція'
					onChange={handleChange}
				>
					{data?.map((p, i) => (
						<MenuItem value={i + 1}>{i + 1}</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

const VideosCreate = () => {
	const notify = useNotify()
	const [video, setVideo] = useState({
		url: '',
		title: '',
		description: '',
		duration: '',
		status: '',
		snippet: '',
		snippetEN: '',
		snippetBlobEN: null,
		snippetBlob: null,
	})
	const [isBanner, setIsBanner] = useState(false)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)
	const ImageInputRefEn = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setVideo(prevState => {
					return {
						...prevState,
						snippet: img.src,
						snippetBlob: event.target.files[0],
					}
				})
			}
		}
	}

	const handleUploadEN = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])


		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setVideo(prevState => {
					return {
						...prevState,
						snippetEN: img.src,
						snippetBlobEN: event.target.files[0],
					}
				})
			}
		}
	}

	const handler = async (url: string) => {
		const video = new YoutubeVideo(url)
		try {
			await video.fetchYoutubeData()
		} catch (e) {
			if (e instanceof ValidationError) {
				notify(e.message, { type: 'error' })
				console.log(e.message)
			}
		}

		setVideo({
			...video.getInstance,
			url,
			status: '',
			snippetBlob: null,
			snippetBlobEN: null,
		})
	}

	const changeVideoByURL = (e: any) => {
		const url = e.target.value

		setVideo({ ...video, url })
		setBlobError('')

		if (url.includes('youtube')) {
			notify('Not YouTube video', { type: 'error' })
		}

		if (url && validateYouTubeURL(url)) {
			handler(url)
		}
	}


	return (
		<Create title='Створити новий ефір' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar video={video} />}>
				{video.url.length === 0 ? (
					<Box width='100%'>
						<TextField
							label='URL'
							required
							onChange={changeVideoByURL}
							fullWidth
						/>
						<BooleanInput
							label='Баннер'
							onChange={e => {
								setIsBanner(e.target.checked)
							}}
							source='is_banner'
						/>
					</Box>
				) : (
					<Box display='flex' flexDirection='column' sx={{ ml: 3, mr: 3 }}>
						<Box display='flex' flex='row' width='100%'>
							<TextField
								fullWidth
								label='URL'
								required
								value={video.url}
								onChange={changeVideoByURL}
							/>
						</Box>
						<Box display='flex' flex='row' width='100%'>
							<BooleanInput label='Показується' source='shown' />
						</Box>
						<Box display='flex' flex='row' width='100%'>
							<BooleanInput
								label='Баннер'
								onChange={e => {
									setIsBanner(e.target.checked)
								}}
								source='is_banner'
							/>
						</Box>
						<PositionSetup />
						<BannerSetup isBanner={isBanner} />
						<ButtonTextSetuo isBanner={isBanner} />
						<Box
							display='flex'
							flex='row'
							width='100%'
							sx={{ mt: 3, mb: 12 }}
							alignItems='center'
							height='auto'
							justifyContent='space-between'
						>
							<Box display='flex' flexDirection='row' gap={3}>
								<Box width='50%'>
									<input
										type='file'
										accept='image/*'
										style={{ display: 'none' }}
										ref={ImageInputRef}
										onChange={handleUpload}
									/>
									<img
										style={{ width: '100%', height: '100%' }}
										src={video.snippet}
										alt={video.title}
									/>

									{blobError && (
										<Alert severity='error' sx={{ mb: 2 }}>
											{blobError}
										</Alert>
									)}

									<Box
										display={'flex'}
										flexDirection={'row'}
										alignItems={'center'}
										justifyContent={'space-between'}
										gap={2}
									>
										<MuiButton
											variant='contained'
											sx={{
												background: '#703eff',
												lineHeight: '18px',
												'&:hover': {
													background: '#703eff',
												},
											}}
											onClick={() => {
												if (ImageInputRef.current) {
													ImageInputRef.current.click()
												}
											}}
										>
											Змінити картинку UA
										</MuiButton>
										<Typography
											sx={{
												fontSize: 14,
												textAlign: 'end',
												width: '70%',
												whiteSpace: 'pre-line',
											}}
										>
											{
												'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
											}
										</Typography>
									</Box>
								</Box>

								{/* Cover EN */}
								<Box width='50%'>
									<input
										type='file'
										accept='image/*'
										style={{ display: 'none' }}
										ref={ImageInputRefEn}
										onChange={handleUploadEN}
									/>
									<img
										style={{ width: '100%', height: '100%' }}
										src={video.snippetEN}
										alt={video.title}
									/>

									{blobError && (
										<Alert severity='error' sx={{ mb: 2 }}>
											{blobError}
										</Alert>
									)}

									<Box
										display={'flex'}
										flexDirection={'row'}
										alignItems={'center'}
										justifyContent={'space-between'}
										gap={2}
									>
										<MuiButton
											variant='contained'
											sx={{
												background: '#703eff',
												lineHeight: '18px',
												'&:hover': {
													background: '#703eff',
												},
											}}
											onClick={() => {
												if (ImageInputRefEn.current) {
													ImageInputRefEn.current.click()
												}
											}}
										>
											Змінити картинку EN
										</MuiButton>
										<Typography
											sx={{
												fontSize: 14,
												textAlign: 'end',
												width: '70%',
												whiteSpace: 'pre-line',
											}}
										>
											{
												'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
											}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>

						{!isBanner ? (
							<Box
								display='flex'
								flex='row'
								// width='100%'
								justifyContent='start'
								alignItems='center'
							>
								<Box
									display='flex'
									flexDirection='column'
									alignContent='center'
								>
									<VideoStatusInput
										onChange={e =>
											setVideo({ ...video, status: e.target.value })
										}
									/>

									{video.status === 'upcoming' ? (
										<DateTimeInput source='premiere_at' label="Дата прем'єри" />
									) : (
										<></>
									)}

									<HtmlTooltip
										title={
											<Fragment>
												<Typography color='inherit'>
													Поле тривалість треба заповнювати завжди
												</Typography>
												<em>
													Взагалі, воно заповнюється автоматично, але його можно
													доповнити власноруч, утримуючись правил:
												</em>
												<ol>
													<li>
														{' '}
														Одиниці часу повинні бути відокремлені двокрапкою,
														наприклад: 01:12:00 ( 1 година 12 хвилин 0 секунд ){' '}
													</li>
													<li>
														{' '}
														Якщо ваше відео не довше години, формат можна
														записати так: 32:00 ( 32 хвилини 0 секунд ){' '}
													</li>
													<li> Секунди краще проставляти також </li>
												</ol>
											</Fragment>
										}
									>
										<TextField
											label='Тривалість'
											value={video.duration}
											onChange={e =>
												setVideo({ ...video, duration: e.target.value })
											}
										/>
									</HtmlTooltip>
								</Box>
								<Box
									display='flex'
									flexDirection='column'
									sx={{ ml: 5, mt: 3 }}
								>
									<FilterInput />

									{(video.status === 'live' || video.status === 'upcoming') && (
										<>
											<Tooltip title='Посилання на онлайн фойє' arrow>
												<TextInput source='zoom' label='Онлайн Фойє' />
											</Tooltip>
											<Tooltip
												title='Поява онлайн фойє (за скільки хвилин до ефіру)'
												arrow
											>
												<NumberInput
													source='online_foyer_time'
													label='Час входу (хвилини)'
													min={0}
												/>
											</Tooltip>
										</>
									)}
								</Box>
							</Box>
						) : null}

						{!isBanner ? (
							<>
								<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
									<TextField
										sx={{ width: '70%' }}
										multiline
										label='Назва Укр'
										required={isBanner}
										value={video.title}
										onChange={e =>
											setVideo({ ...video, title: e.target.value })
										}
									/>

									<TextField
										multiline
										sx={{ ml: 5, width: '100%' }}
										label='Опис Укр'
										required
										value={video.description}
										onChange={e =>
											setVideo({ ...video, description: e.target.value })
										}
									/>
								</Box>

								<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
									<TextInput
										sx={{ width: '70%' }}
										multiline
										label='Назва Eng'
										validate={required()}
										source='title_en'
									/>
									<TextInput
										multiline
										sx={{ ml: 5, width: '100%' }}
										label='Опис Eng'
										source='description_en'
										validate={required()}
									/>
								</Box>
							</>
						) : null}
					</Box>
				)}
			</SimpleForm>
		</Create>
	)
}

export default VideosCreate
