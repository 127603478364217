import { Box } from '@mui/material'
import { FC, useState } from 'react'
import {
	BooleanField,
	Button,
	Datagrid,
	DateField,
	FunctionField,
	ImageField,
	List,
	TextField,
	UrlField,
	useRecordContext,
} from 'react-admin'

const DescriptionField: FC<{ label: string }> = ({ label }) => {
	const { description } = useRecordContext()
	const [text, setText] = useState(
		description && description.length < 100
			? description
			: description
			? `${description.slice(0, 100)}...`
			: ''
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text ?? ''} </span>
					{description && description.length >= 100 && (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(
										pressed ? `${description.slice(0, 100)}...` : description
									)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

const DescriptionENField: FC<{ label: string }> = ({ label }) => {
	const { description_en } = useRecordContext()
	const [text, setText] = useState(
		description_en && description_en.length < 100
			? description_en
			: description_en
			? `${description_en.slice(0, 100)}...`
			: ''
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text ?? ''} </span>
					{description_en && description_en.length >= 100 && (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(
										pressed
											? `${description_en.slice(0, 100)}...`
											: description_en
									)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

const FoundationsList = () => {
	return (
		<List
			title='Пожертви'
			sx={{ tableLayout: 'fixed' }}
			// actions={<ListActions />}
			// filters={<TagFilter />}
		>
			<Datagrid
				// bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					backgroundColor: '#f5f5f5',
				}}
			>
				<TextField label='Назва' source='title' />
				<TextField label='Назва EN' source='title_en' />

				<DescriptionField label='Опис' />
				<DescriptionENField label='Опис EN' />

				<UrlField label='Лінк' source='link' />

				<BooleanField
					label='Показується'
					source='shown'
					valueLabelTrue='Так'
					valueLabelFalse='Ні'
				/>
				<BooleanField
					label='Темний текст'
					source='dark_text'
					valueLabelTrue='Так'
					valueLabelFalse='Ні'
				/>
				<ImageField label='Фото' source='cover' />
				<DateField label='Створено' source='created_at' />
			</Datagrid>
		</List>
	)
}

export default FoundationsList
