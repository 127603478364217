import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    }
});

export default theme;