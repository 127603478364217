import {Chip} from '@mui/material';
import {FC, useState, useEffect} from 'react';
import {FunctionField, useRecordContext} from 'react-admin';

const PrayerField: FC<{
    label: string;
}> = ({label}) => {
    const {type} = useRecordContext();

    const [chipColor, setChipColor] = useState(
        'linear-gradient(90deg, rgb(34, 172, 106) 0%, rgb(19, 213, 120) 100%)',
    );
    const [chipText, setChipText] = useState('Молитва');

    useEffect(() => {
        switch (type) {
            case 'praise':
                setChipText('Свідотство');
                setChipColor('linear-gradient(90deg, rgb(34, 172, 106) 0%, rgb(19, 213, 120) 100%)');
                break;
            case 'prayer':
                setChipText('Молитва');
                setChipColor('linear-gradient(90deg, rgb(41, 190, 223) 0%, rgb(0, 164, 255) 100%)');
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <FunctionField
            label={label}
            render={() => (
                <Chip
                    label={chipText}
                    sx={{
                        background: chipColor,
                        borderRadius: 2,
                        color: 'white',
                        fontWeight: 600,
                    }}
                />
            )}
        />
    );
};

export default PrayerField;
