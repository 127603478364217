import { Chip } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

const StatusField: FC<{
  label: string;
  accepted_label?: string;
  pending_label?: string;
  rejected_label?: string;
}> = ({ label, accepted_label, pending_label, rejected_label }) => {
  const { status } = useRecordContext();

  const [chipColor, setChipColor] = useState('#F8BA03');
  const [chipText, setChipText] = useState('Очікування' || pending_label);

  useEffect(() => {
    switch (status) {
      case 'pending':
        setChipText(pending_label || 'Очікування');
        setChipColor('#F8BA03');
        break;
      case 'accepted':
        setChipText(accepted_label || 'Прийнято');
        setChipColor('#00BE13');
        break;
      case 'rejected':
        setChipText(rejected_label || 'Відхилено');
        setChipColor('#ED210B');
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Chip
          label={chipText}
          sx={{
            background: chipColor,
            borderRadius: 2,
            color: 'white',
            fontWeight: 600,
          }}
        />
      )}
    />
  );
};

export default StatusField;
