import './index.css';

import {useEffect, useState} from 'react';
import {signOut, onAuthStateChanged} from 'firebase/auth';
import {auth} from './lib/firebase';

import {ThemeProvider} from '@mui/material/styles';
import theme from './lib/theme';

import {fetchUser} from './api';
import {AuthContext} from './lib/contexts';
import RootWrapper from './containers/root-wrapper';
import Spinner from './components/common/Spinner';
import {AccessContext} from "./containers/access-provider";
import {REACT_APP_IS_DEVELOPMENT} from "./constants/constants";

const App = () => {

    // todo: remove it after test on dev
    console.log("REACT_APP_IS_DEVELOPMENT:", REACT_APP_IS_DEVELOPMENT);
    console.log("env REACT_APP_IS_DEVELOPMENT:", process.env.REACT_APP_IS_DEVELOPMENT);
    console.log("env:", Object.keys(process.env), Object.values(process.env));

    const [loadedUser, setLoadedUser] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [accesses, setAccesses] = useState({});
    const handleFirebaseAuthState = async (firebaseUser) => {
        if (firebaseUser) {
            const user = await fetchUser(firebaseUser);

            if (user && (user.access_level === 'moderator' || user.access_level === 'coach')) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
                await signOut(auth);
                console.error('Error', 'User data mismatch, please contact support.');
            }
        } else {
            setCurrentUser(null);
        }
        setLoadedUser(true);
    };

    useEffect(() => {
        onAuthStateChanged(auth, handleFirebaseAuthState);
    }, []);

    useEffect(() => {
        if (loadedUser) {
            setIsReady(true);
        }
    }, [loadedUser]);

    if (!isReady) {
        return <Spinner size={60}/>;
    }

    return (
        <AccessContext.Provider value={{accesses, setAccesses}}>
            <AuthContext.Provider value={{currentUser, setCurrentUser}}>
                <ThemeProvider theme={theme}>
                    <RootWrapper/>
                </ThemeProvider>
            </AuthContext.Provider>
        </AccessContext.Provider>
    );
};

export default App;
