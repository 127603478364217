import { FC, useRef, useState } from 'react'
import {
  BooleanInput,
  Button,
  Create,
  DateTimeInput,
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { Alert, Button as MuiButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { download, storageRef, UploadVideoSnippet } from '../../lib/firebase'

const CustomToolBar: FC<{ blob: Blob | null; blobEn: Blob | null }> = ({
  blob,
  blobEn,
}) => {
  const form = useFormContext()
  const [create, { isLoading }] = useCreate()

  const redirect = useRedirect()

  const SaveHandler = async () => {
    try {
      const values = form.getValues()
      console.log('values', values)

      let cover
      let cover_en

      console.log('blob', blob)
      console.log('blobEn', blobEn)

      if (blob) {
        const snapshot = await UploadVideoSnippet(
          values.name.replace(/\s/g, ''),
          blob
        )
        cover = await download(storageRef(snapshot.metadata.fullPath))
      }

      if (blobEn) {
        const snapshot = await UploadVideoSnippet(
          values.name_en.replace(/\s/g, ''),
          blobEn
        )
        cover_en = await download(storageRef(snapshot.metadata.fullPath))
      }

      const data = {
        name: values.name,
        name_en: values.name_en,
        cover,
        cover_en: cover_en,
        description: values.description,
        description_en: values.description_en,
        link: values.link,
        date: values.date,
        shown: values.shown,
        video_id: values.video_id,
      }

      await create('events', { data })
      redirect('/events')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        size='medium'
        disabled={isLoading}
        label='Зберегти'
        onClick={SaveHandler}
      />
    </Toolbar>
  )
}

const EventsCreate = () => {
  const notify = useNotify()
  const [blob, setBlob] = useState<Blob | null>(null)
  const [blob_en, setBlob_EN] = useState<Blob | null>(null)

  const [blobError, setBlobError] = useState<string>('')

  const ImageInputRef = useRef<HTMLInputElement>(null)
  const ImageInputRefEN = useRef<HTMLInputElement>(null)

  const handleUpload = (event: any) => {
    const img = new Image()
    img.src = URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      const { height, width } = img

      if ((width / height).toFixed(2) !== '1.78') {
        setBlobError('Додайте зображення з відношенням сторін 16 на 9')
        return
      } else if (
        height < 720 ||
        height > 1080 ||
        width < 1280 ||
        width > 1920
      ) {
        setBlobError(
          'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
        )
        return
      } else {
        setBlobError('')
        setBlob(event.target.files[0])
      }
    }
  }

  const handleUploadEN = (event: any) => {
    const img = new Image()
    img.src = URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      const { height, width } = img

      if ((width / height).toFixed(2) !== '1.78') {
        setBlobError('Додайте зображення з відношенням сторін 16 на 9')
        return
      } else if (
        height < 720 ||
        height > 1080 ||
        width < 1280 ||
        width > 1920
      ) {
        setBlobError(
          'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
        )
        return
      } else {
        setBlobError('')
        setBlob_EN(event.target.files[0])
      }
    }
  }

  return (
    <Create title='Створити нову подію' sx={{ tableLayout: 'fixed' }}>
      <SimpleForm toolbar={<CustomToolBar blob={blob} blobEn={blob_en} />}>
        <Box display='flex' flexDirection='row' width='100%' gap={5}>
          <TextInput
            fullWidth
            validate={required()}
            label='Назва Українською'
            source='name'
          />
          <TextInput
            fullWidth
            validate={required()}
            label='Назва Англійською'
            source='name_en'
          />
        </Box>
        <Box display='flex' flexDirection='row' width='100%' gap={5}>
          <TextInput
            multiline
            fullWidth
            validate={required()}
            label='Опис Українською'
            source='description'
          />
          <TextInput
            multiline
            fullWidth
            validate={required()}
            label='Опис Англійською'
            source='description_en'
          />
        </Box>
        <Box display='flex' flex='row' alignItems="center" width='100%'>
          <BooleanInput label='Показується' source='shown' />
          <DateTimeInput validate={required()} label='Дата проведення' source='date' />
        </Box>
        <Box display='flex' flexDirection='row' width='100%'>
          <TextInput
            fullWidth
            label='Link'
            source='link'
          />
        </Box>

        <Box display='flex' flexDirection='column' width='100%'>
          <h4>Поля для застосунку</h4>
          <TextInput
            fullWidth
            label='Відео ID'
            source='video_id'
          />
        </Box>

        <Box
          display='flex'
          flex='row'
          width='100%'
          sx={{ mt: 3 }}
          alignItems='center'
          gap={3}
        >
          <Box>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              ref={ImageInputRef}
              onChange={handleUpload}
            />
            <img
              style={{ width: '100%', height: '100%' }}
              src={
                blob
                  ? URL.createObjectURL(blob)
                  : 'https://dummyimage.com/hd1080'
              }
              alt='photo'
            />

            {blobError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {blobError}
              </Alert>
            )}

            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <MuiButton
                variant='contained'
                sx={{
                  background: '#703eff',
                  lineHeight: '18px',
                  '&:hover': {
                    background: '#703eff',
                  },
                }}
                onClick={() => {
                  if (ImageInputRef.current) {
                    ImageInputRef.current.click()
                  }
                }}
              >
                Змінити картинку
              </MuiButton>
              <Typography
                sx={{
                  fontSize: 14,
                  textAlign: 'end',
                  width: '70%',
                  whiteSpace: 'pre-line',
                }}
              >
                {
                  'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
                }
              </Typography>
            </Box>
          </Box>

          {/*COVER EN*/}
          <Box>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              ref={ImageInputRefEN}
              onChange={handleUploadEN}
            />
            <img
              style={{ width: '100%', height: '100%' }}
              src={
                blob_en
                  ? URL.createObjectURL(blob_en)
                  : 'https://dummyimage.com/hd1080'
              }
              alt='photo'
            />

            {blobError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {blobError}
              </Alert>
            )}

            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <MuiButton
                variant='contained'
                sx={{
                  background: '#703eff',
                  lineHeight: '18px',
                  '&:hover': {
                    background: '#703eff',
                  },
                }}
                onClick={() => {
                  if (ImageInputRefEN.current) {
                    ImageInputRefEN.current.click()
                  }
                }}
              >
                Змінити картинку
              </MuiButton>
              <Typography
                sx={{
                  fontSize: 14,
                  textAlign: 'end',
                  width: '70%',
                  whiteSpace: 'pre-line',
                }}
              >
                {
                  'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}

export default EventsCreate
