import { Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { signOut } from 'firebase/auth';
import { auth } from '../../lib/firebase';

import { AuthContext } from '../../lib/contexts';

const LogoutButton = () => {
  const { setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSignOut = () => {
    setCurrentUser(null);
    signOut(auth).then(() => navigate('/login'));
  };

  return (
    <Button onClick={onSignOut} size="medium" sx={{ mx: 1 }}>
      <ExitToAppIcon sx={{ marginRight: 1 }} />
      <Typography component="div" sx={{ fontSize: 14 }}>
        Вийти
      </Typography>
    </Button>
  );
};

export default LogoutButton;
