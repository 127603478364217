import {useContext} from 'react';
import {Navigate} from 'react-router-dom';

import {AuthContext} from '../../lib/contexts';

import AdminPanel from '../../pages/adminPanel';

const PrivateRoute = () => {
    const {currentUser} = useContext(AuthContext);
    return currentUser ? <AdminPanel/> : <Navigate to="/login" replace/>;
};

export default PrivateRoute;
