import Country from "./types/country";

export const countries: Country[] = [
    {"code":"AD","name":"Andorra","id":"Andorra"},
	{"code":"AE","name":"United Arab Emirates","id":"United Arab Emirates"},
	{"code":"AF","name":"Afghanistan","id":"Afghanistan"},
	{"code":"AG","name":"Antigua and Barbuda","id":"Antigua and Barbuda"},
	{"code":"AI","name":"Anguilla","id":"Anguilla"},
	{"code":"AL","name":"Albania","id":"Albania"},
	{"code":"AM","name":"Armenia","id":"Armenia"},
	{"code":"AO","name":"Angola","id":"Angola"},
	{"code":"AQ","name":"Antarctica","id":"Antarctica"},
	{"code":"AR","name":"Argentina","id":"Argentina"},
	{"code":"AS","name":"American Samoa","id":"American Samoa"},
	{"code":"AT","name":"Austria","id":"Austria"},
	{"code":"AU","name":"Australia","id":"Australia"},
	{"code":"AW","name":"Aruba","id":"Aruba"},
	{"code":"AX","name":"Alland Islands","id":"Alland Islands"},
	{"code":"AZ","name":"Azerbaijan","id":"Azerbaijan"},
	{"code":"BA","name":"Bosnia and Herzegovina","id":"Bosnia and Herzegovina"},
	{"code":"BB","name":"Barbados","id":"Barbados"},
	{"code":"BD","name":"Bangladesh","id":"Bangladesh"},
	{"code":"BE","name":"Belgium","id":"Belgium"},
	{"code":"BF","name":"Burkina Faso","id":"Burkina Faso"},
	{"code":"BG","name":"Bulgaria","id":"Bulgaria"},
	{"code":"BH","name":"Bahrain","id":"Bahrain"},
	{"code":"BI","name":"Burundi","id":"Burundi"},
	{"code":"BJ","name":"Benin","id":"Benin"},
	{"code":"BL","name":"Saint Barthelemy","id":"Saint Barthelemy"},
	{"code":"BM","name":"Bermuda","id":"Bermuda"},
	{"code":"BN","name":"Brunei Darussalam","id":"Brunei Darussalam"},
	{"code":"BO","name":"Bolivia","id":"Bolivia"},
	{"code":"BR","name":"Brazil","id":"Brazil"},
	{"code":"BS","name":"Bahamas","id":"Bahamas"},
	{"code":"BT","name":"Bhutan","id":"Bhutan"},
	{"code":"BV","name":"Bouvet Island","id":"Bouvet Island"},
	{"code":"BW","name":"Botswana","id":"Botswana"},
	{"code":"BY","name":"Belarus","id":"Belarus"},
	{"code":"BZ","name":"Belize","id":"Belize"},
	{"code":"CA","name":"Canada","id":"Canada"},
	{"code":"CC","name":"Cocos (Keeling) Islands","id":"Cocos (Keeling) Islands"},
	{"code":"CD","name":"Congo, Democratic Republic of the","id":"Congo, Democratic Republic of the"},
	{"code":"CF","name":"Central African Republic","id":"Central African Republic"},
	{"code":"CG","name":"Congo, Republic of the","id":"Congo, Republic of the"},
	{"code":"CH","name":"Switzerland","id":"Switzerland"},
	{"code":"CI","name":"Cote d'Ivoire","id":"Cote d'Ivoire"},
	{"code":"CK","name":"Cook Islands","id":"Cook Islands"},
	{"code":"CL","name":"Chile","id":"Chile"},
	{"code":"CM","name":"Cameroon","id":"Cameroon"},
	{"code":"CN","name":"China","id":"China"},
	{"code":"CO","name":"Colombia","id":"Colombia"},
	{"code":"CR","name":"Costa Rica","id":"Costa Rica"},
	{"code":"CU","name":"Cuba","id":"Cuba"},
	{"code":"CV","name":"Cape Verde","id":"Cape Verde"},
	{"code":"CW","name":"Curacao","id":"Curacao"},
	{"code":"CX","name":"Christmas Island","id":"Christmas Island"},
	{"code":"CY","name":"Cyprus","id":"Cyprus"},
	{"code":"CZ","name":"Czech Republic","id":"Czech Republic"},
	{"code":"DE","name":"Germany","id":"Germany"},
	{"code":"DJ","name":"Djibouti","id":"Djibouti"},
	{"code":"DK","name":"Denmark","id":"Denmark"},
	{"code":"DM","name":"Dominica","id":"Dominica"},
	{"code":"DO","name":"Dominican Republic","id":"Dominican Republic"},
	{"code":"DZ","name":"Algeria","id":"Algeria"},
	{"code":"EC","name":"Ecuador","id":"Ecuador"},
	{"code":"EE","name":"Estonia","id":"Estonia"},
	{"code":"EG","name":"Egypt","id":"Egypt"},
	{"code":"EH","name":"Western Sahara","id":"Western Sahara"},
	{"code":"ER","name":"Eritrea","id":"Eritrea"},
	{"code":"ES","name":"Spain","id":"Spain"},
	{"code":"ET","name":"Ethiopia","id":"Ethiopia"},
	{"code":"FI","name":"Finland","id":"Finland"},
	{"code":"FJ","name":"Fiji","id":"Fiji"},
	{"code":"FK","name":"Falkland Islands (Malvinas)","id":"Falkland Islands (Malvinas)"},
	{"code":"FM","name":"Micronesia, Federated States of","id":"Micronesia, Federated States of"},
	{"code":"FO","name":"Faroe Islands","id":"Faroe Islands"},
	{"code":"FR","name":"France","id":"France"},
	{"code":"GA","name":"Gabon","id":"Gabon"},
	{"code":"GB","name":"United Kingdom","id":"United Kingdom"},
	{"code":"GD","name":"Grenada","id":"Grenada"},
	{"code":"GE","name":"Georgia","id":"Georgia"},
	{"code":"GF","name":"French Guiana","id":"French Guiana"},
	{"code":"GG","name":"Guernsey","id":"Guernsey"},
	{"code":"GH","name":"Ghana","id":"Ghana"},
	{"code":"GI","name":"Gibraltar","id":"Gibraltar"},
	{"code":"GL","name":"Greenland","id":"Greenland"},
	{"code":"GM","name":"Gambia","id":"Gambia"},
	{"code":"GN","name":"Guinea","id":"Guinea"},
	{"code":"GP","name":"Guadeloupe","id":"Guadeloupe"},
	{"code":"GQ","name":"Equatorial Guinea","id":"Equatorial Guinea"},
	{"code":"GR","name":"Greece","id":"Greece"},
	{"code":"GS","name":"South Georgia and the South Sandwich Islands","id":"South Georgia and the South Sandwich Islands"},
	{"code":"GT","name":"Guatemala","id":"Guatemala"},
	{"code":"GU","name":"Guam","id":"Guam"},
	{"code":"GW","name":"Guinea-Bissau","id":"Guinea-Bissau"},
	{"code":"GY","name":"Guyana","id":"Guyana"},
	{"code":"HK","name":"Hong Kong","id":"Hong Kong"},
	{"code":"HM","name":"Heard Island and McDonald Islands","id":"Heard Island and McDonald Islands"},
	{"code":"HN","name":"Honduras","id":"Honduras"},
	{"code":"HR","name":"Croatia","id":"Croatia"},
	{"code":"HT","name":"Haiti","id":"Haiti"},
	{"code":"HU","name":"Hungary","id":"Hungary"},
	{"code":"ID","name":"Indonesia","id":"Indonesia"},
	{"code":"IE","name":"Ireland","id":"Ireland"},
	{"code":"IL","name":"Israel","id":"Israel"},
	{"code":"IM","name":"Isle of Man","id":"Isle of Man"},
	{"code":"IN","name":"India","id":"India"},
	{"code":"IO","name":"British Indian Ocean Territory","id":"British Indian Ocean Territory"},
	{"code":"IQ","name":"Iraq","id":"Iraq"},
	{"code":"IR","name":"Iran, Islamic Republic of","id":"Iran, Islamic Republic of"},
	{"code":"IS","name":"Iceland","id":"Iceland"},
	{"code":"IT","name":"Italy","id":"Italy"},
	{"code":"JE","name":"Jersey","id":"Jersey"},
	{"code":"JM","name":"Jamaica","id":"Jamaica"},
	{"code":"JO","name":"Jordan","id":"Jordan"},
	{"code":"JP","name":"Japan","id":"Japan"},
	{"code":"KE","name":"Kenya","id":"Kenya"},
	{"code":"KG","name":"Kyrgyzstan","id":"Kyrgyzstan"},
	{"code":"KH","name":"Cambodia","id":"Cambodia"},
	{"code":"KI","name":"Kiribati","id":"Kiribati"},
	{"code":"KM","name":"Comoros","id":"Comoros"},
	{"code":"KN","name":"Saint Kitts and Nevis","id":"Saint Kitts and Nevis"},
	{"code":"KP","name":"Korea, Democratic People's Republic of","id":"Korea, Democratic People's Republic of"},
	{"code":"KR","name":"Korea, Republic of","id":"Korea, Republic of"},
	{"code":"KW","name":"Kuwait","id":"Kuwait"},
	{"code":"KY","name":"Cayman Islands","id":"Cayman Islands"},
	{"code":"KZ","name":"Kazakhstan","id":"Kazakhstan"},
	{"code":"LA","name":"Lao People's Democratic Republic","id":"Lao People's Democratic Republic"},
	{"code":"LB","name":"Lebanon","id":"Lebanon"},
	{"code":"LC","name":"Saint Lucia","id":"Saint Lucia"},
	{"code":"LI","name":"Liechtenstein","id":"Liechtenstein"},
	{"code":"LK","name":"Sri Lanka","id":"Sri Lanka"},
	{"code":"LR","name":"Liberia","id":"Liberia"},
	{"code":"LS","name":"Lesotho","id":"Lesotho"},
	{"code":"LT","name":"Lithuania","id":"Lithuania"},
	{"code":"LU","name":"Luxembourg","id":"Luxembourg"},
	{"code":"LV","name":"Latvia","id":"Latvia"},
	{"code":"LY","name":"Libya","id":"Libya"},
	{"code":"MA","name":"Morocco","id":"Morocco"},
	{"code":"MC","name":"Monaco","id":"Monaco"},
	{"code":"MD","name":"Moldova, Republic of","id":"Moldova, Republic of"},
	{"code":"ME","name":"Montenegro","id":"Montenegro"},
	{"code":"MF","name":"Saint Martin (French part)","id":"Saint Martin (French part)"},
	{"code":"MG","name":"Madagascar","id":"Madagascar"},
	{"code":"MH","name":"Marshall Islands","id":"Marshall Islands"},
	{"code":"MK","name":"Macedonia, the Former Yugoslav Republic of","id":"Macedonia, the Former Yugoslav Republic of"},
	{"code":"ML","name":"Mali","id":"Mali"},
	{"code":"MM","name":"Myanmar","id":"Myanmar"},
	{"code":"MN","name":"Mongolia","id":"Mongolia"},
	{"code":"MO","name":"Macao","id":"Macao"},
	{"code":"MP","name":"Northern Mariana Islands","id":"Northern Mariana Islands"},
	{"code":"MQ","name":"Martinique","id":"Martinique"},
	{"code":"MR","name":"Mauritania","id":"Mauritania"},
	{"code":"MS","name":"Montserrat","id":"Montserrat"},
	{"code":"MT","name":"Malta","id":"Malta"},
	{"code":"MU","name":"Mauritius","id":"Mauritius"},
	{"code":"MV","name":"Maldives","id":"Maldives"},
	{"code":"MW","name":"Malawi","id":"Malawi"},
	{"code":"MX","name":"Mexico","id":"Mexico"},
	{"code":"MY","name":"Malaysia","id":"Malaysia"},
	{"code":"MZ","name":"Mozambique","id":"Mozambique"},
	{"code":"NA","name":"Namibia","id":"Namibia"},
	{"code":"NC","name":"New Caledonia","id":"New Caledonia"},
	{"code":"NE","name":"Niger","id":"Niger"},
	{"code":"NF","name":"Norfolk Island","id":"Norfolk Island"},
	{"code":"NG","name":"Nigeria","id":"Nigeria"},
	{"code":"NI","name":"Nicaragua","id":"Nicaragua"},
	{"code":"NL","name":"Netherlands","id":"Netherlands"},
	{"code":"NO","name":"Norway","id":"Norway"},
	{"code":"NP","name":"Nepal","id":"Nepal"},
	{"code":"NR","name":"Nauru","id":"Nauru"},
	{"code":"NU","name":"Niue","id":"Niue"},
	{"code":"NZ","name":"New Zealand","id":"New Zealand"},
	{"code":"OM","name":"Oman","id":"Oman"},
	{"code":"PA","name":"Panama","id":"Panama"},
	{"code":"PE","name":"Peru","id":"Peru"},
	{"code":"PF","name":"French Polynesia","id":"French Polynesia"},
	{"code":"PG","name":"Papua New Guinea","id":"Papua New Guinea"},
	{"code":"PH","name":"Philippines","id":"Philippines"},
	{"code":"PK","name":"Pakistan","id":"Pakistan"},
	{"code":"PL","name":"Poland","id":"Poland"},
	{"code":"PM","name":"Saint Pierre and Miquelon","id":"Saint Pierre and Miquelon"},
	{"code":"PN","name":"Pitcairn","id":"Pitcairn"},
	{"code":"PR","name":"Puerto Rico","id":"Puerto Rico"},
	{"code":"PS","name":"Palestine, State of","id":"Palestine, State of"},
	{"code":"PT","name":"Portugal","id":"Portugal"},
	{"code":"PW","name":"Palau","id":"Palau"},
	{"code":"PY","name":"Paraguay","id":"Paraguay"},
	{"code":"QA","name":"Qatar","id":"Qatar"},
	{"code":"RE","name":"Reunion","id":"Reunion"},
	{"code":"RO","name":"Romania","id":"Romania"},
	{"code":"RS","name":"Serbia","id":"Serbia"},
	// {"code":"RU","name":"Russian Federation","id":"Russian Federation"},
	{"code":"RW","name":"Rwanda","id":"Rwanda"},
	{"code":"SA","name":"Saudi Arabia","id":"Saudi Arabia"},
	{"code":"SB","name":"Solomon Islands","id":"Solomon Islands"},
	{"code":"SC","name":"Seychelles","id":"Seychelles"},
	{"code":"SD","name":"Sudan","id":"Sudan"},
	{"code":"SE","name":"Sweden","id":"Sweden"},
	{"code":"SG","name":"Singapore","id":"Singapore"},
	{"code":"SH","name":"Saint Helena","id":"Saint Helena"},
	{"code":"SI","name":"Slovenia","id":"Slovenia"},
	{"code":"SJ","name":"Svalbard and Jan Mayen","id":"Svalbard and Jan Mayen"},
	{"code":"SK","name":"Slovakia","id":"Slovakia"},
	{"code":"SL","name":"Sierra Leone","id":"Sierra Leone"},
	{"code":"SM","name":"San Marino","id":"San Marino"},
	{"code":"SN","name":"Senegal","id":"Senegal"},
	{"code":"SO","name":"Somalia","id":"Somalia"},
	{"code":"SR","name":"Suriname","id":"Suriname"},
	{"code":"SS","name":"South Sudan","id":"South Sudan"},
	{"code":"ST","name":"Sao Tome and Principe","id":"Sao Tome and Principe"},
	{"code":"SV","name":"El Salvador","id":"El Salvador"},
	{"code":"SX","name":"Sint Maarten (Dutch part)","id":"Sint Maarten (Dutch part)"},
	{"code":"SY","name":"Syrian Arab Republic","id":"Syrian Arab Republic"},
	{"code":"SZ","name":"Swaziland","id":"Swaziland"},
	{"code":"TC","name":"Turks and Caicos Islands","id":"Turks and Caicos Islands"},
	{"code":"TD","name":"Chad","id":"Chad"},
	{"code":"TF","name":"French Southern Territories","id":"French Southern Territories"},
	{"code":"TG","name":"Togo","id":"Togo"},
	{"code":"TH","name":"Thailand","id":"Thailand"},
	{"code":"TJ","name":"Tajikistan","id":"Tajikistan"},
	{"code":"TK","name":"Tokelau","id":"Tokelau"},
	{"code":"TL","name":"Timor-Leste","id":"Timor-Leste"},
	{"code":"TM","name":"Turkmenistan","id":"Turkmenistan"},
	{"code":"TN","name":"Tunisia","id":"Tunisia"},
	{"code":"TO","name":"Tonga","id":"Tonga"},
	{"code":"TR","name":"Turkey","id":"Turkey"},
	{"code":"TT","name":"Trinidad and Tobago","id":"Trinidad and Tobago"},
	{"code":"TV","name":"Tuvalu","id":"Tuvalu"},
	{"code":"TW","name":"Taiwan, Province of China","id":"Taiwan, Province of China"},
	{"code":"TZ","name":"United Republic of Tanzania","id":"United Republic of Tanzania"},
	{"code":"UA","name":"Україна","id":"Ukraine"},
	{"code":"UG","name":"Uganda","id":"Uganda"},
	{"code":"US","name":"United States","id":"United States"},
	{"code":"UY","name":"Uruguay","id":"Uruguay"},
	{"code":"UZ","name":"Uzbekistan","id":"Uzbekistan"},
	{"code":"VA","name":"Holy See (Vatican City State)","id":"Holy See (Vatican City State)"},
	{"code":"VC","name":"Saint Vincent and the Grenadines","id":"Saint Vincent and the Grenadines"},
	{"code":"VE","name":"Venezuela","id":"Venezuela"},
	{"code":"VG","name":"British Virgin Islands","id":"British Virgin Islands"},
	{"code":"VI","name":"US Virgin Islands","id":"US Virgin Islands"},
	{"code":"VN","name":"Vietnam","id":"Vietnam"},
	{"code":"VU","name":"Vanuatu","id":"Vanuatu"},
	{"code":"WF","name":"Wallis and Futuna","id":"Wallis and Futuna"},
	{"code":"WS","name":"Samoa","id":"Samoa"},
	{"code":"XK","name":"Kosovo","id":"Kosovo"},
	{"code":"YE","name":"Yemen","id":"Yemen"},
	{"code":"YT","name":"Mayotte","id":"Mayotte"},
	{"code":"ZA","name":"South Africa","id":"South Africa"},
	{"code":"ZM","name":"Zambia","id":"Zambia"},
	{"code":"ZW","name":"Zimbabwe","id":"Zimbabwe"}
]