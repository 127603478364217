import { Box } from '@mui/system';
import { BulkDeleteButton } from 'react-admin';

const BulkActionButtons = () => {
  return (
    <Box mb={1}>
      <BulkDeleteButton label="Видалити" />
    </Box>
  );
};

export default BulkActionButtons;
