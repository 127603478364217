import { Stack } from "@mui/material";
import {
  CreateButton,
  Datagrid,
  DateField,
  EmailField,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useDataProvider,
} from "react-admin";

import { useEffect, useState } from "react";
import AccessLevelField from "../../components/common/AccessLevelField";
import BulkActionButtons from "../../components/common/BulkActionButtons";
//import CoachInput from "../../components/common/inputs/CoachInput";

interface Coach {
  id: string;
  name: string;
}

// const SelectCoach = (props: { label: string; source: string }) => {
// 	const { label, source } = props
// 	const [coachUsers, setCoachUsers] = useState<Coach[]>([])
// 	const dataProvider = useDataProvider()

// 	useEffect(() => {
// 		const fetchCoaches = async () => {
// 			try {
// 				const { data } = await dataProvider.getList<Coach>('users', {
// 					pagination: { page: 1, perPage: 100000 },
// 					sort: { field: 'id', order: 'asc' },
// 					filter: { access_level: 'coach' },
// 				})
// 				const coachChoices = data.map(coach => ({
// 					id: coach.id,
// 					name: coach.name,
// 				}))
// 				setCoachUsers(coachChoices)
// 			} catch (error) {
// 				console.error('Error fetching coach users:', error)
// 				setCoachUsers([]) // Ensure coachUsers is set to an empty array on error
// 			}
// 		}
// 		fetchCoaches()
// 	}, [dataProvider])

// 	return <SelectInput label={label} source={source} choices={coachUsers} />
// }

interface Coach {
  id: string;
  name: string;
}

const SelectCoach = (props: { label: string; source: string }) => {
  const { label, source } = props;
  const [coachUsers, setCoachUsers] = useState<Coach[]>([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const { data } = await dataProvider.getList<Coach>("users", {
          pagination: { page: 1, perPage: 100000 },
          sort: { field: "id", order: "ASC" },
          filter: { access_level: "coach" },
        });
        const coachChoices = data.map((coach) => ({
          id: coach.id,
          name: coach.name,
        }));
        setCoachUsers(coachChoices);
      } catch (error) {
        console.error("Error fetching coach users:", error);
        setCoachUsers([]); // Ensure coachUsers is set to an empty array on error
      }
    };
    fetchCoaches();
  }, [dataProvider]);

  return <SelectInput label={label} source={source} choices={coachUsers} />;
};

const postFilters = [
  <TextInput label="За ім'ям" source="name" />,
  <TextInput label="За поштою" source="email" />,
  <TextInput label="За телефоном" source="phone" />,
  <TextInput label="За країною" source="country" />,
  <TextInput label="За містом" source="region" />,
  <TextInput label="За церквою" source="church" />,
  <SelectInput
    label="За доступом"
    source="access_level"
    validate={required()}
    choices={[
      { id: "user", name: "Користувач" },
      { id: "coach", name: "Лідер" },
      { id: "moderator", name: "Модератор" },
    ]}
  />,
  <SelectCoach label="За лідером" source="coach_id" />,
  // <SelectInput label='За Лідером' source='coach_id' choices={SelectCoach()} />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton className="filter" filters={postFilters} />
    <CreateButton label="Створити" />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);

const UsersList = () => {
  return (
    <List
      title="Користувачі"
      sx={{ tableLayout: "fixed" }}
      actions={<ListActions />}
      filters={<TagFilter />}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons />}
        rowClick="edit"
        sx={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <TextField label="ПІБ" source="name" />
        <EmailField label="Пошта" source="email" />
        <TextField label="Телефон" source="phone" />
        <TextField label="Країна" source="country" />
        <TextField label="Місто" source="region" />,
        <TextField label="Церква" source="church" />
        <DateField label="Зареєстрован" source="created_at" />
        <AccessLevelField label="Рівень доступу" />
        <ReferenceField label="Лідер" source="coach_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default UsersList;
