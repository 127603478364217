import {
  BooleanInput,
  Button,
  DateInput,
  DeleteButton,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
  useRecordContext,
  useRedirect,
  useSaveContext,
  useUpdate,
  useNotify,
} from 'react-admin'
import Box from '@mui/material/Box'
import { useFormContext } from 'react-hook-form'
import { FilterInput } from './AccessLevelCreate'

const AccessLevelTitle = () => {
  const record = useRecordContext()
  return <span> {record?.name} </span>
}

const CustomSaveButton = () => {
  const notify = useNotify()

  const [update, { isLoading }] = useUpdate()
  const form = useFormContext()
  const redirect = useRedirect()
  const { id } = useRecordContext()



  const onSave = async () => {
    try {
      const values = form.getValues();
      console.log('values', values)
      // delete values.id

      const data = {
        name: values.name,
        filter_ids: values.filter_ids
      }

      if (!data.name) {
        notify('Введіть назву', { type: 'error' })
        return
      }

      await update('access_level', { id, data })
      redirect('/access_level')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Button
      label='Зберегти'
      disabled={isLoading}
      size='medium'
      onClick={onSave}
    />
  )
}

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <CustomSaveButton />
      <DeleteButton label='Видалити' />
    </Toolbar>
  )
}

const AccessLevelEdit = () => {
  return <Edit title={<AccessLevelTitle />}>
    <SimpleForm toolbar={<CustomToolBar />}>
      <Box display="flex" sx={{ gap: 3 }}>
        <Box >
          <TextInput label="Назва" source="name" validate={required()} />
        </Box>
        <Box >
          <FilterInput />
        </Box>
      </Box>
    </SimpleForm>
  </Edit>
}

export default AccessLevelEdit;
