import { useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";

const FilterInput = () => {
    const [filters, setFilters] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
        .getList('filters', {})
        .then(({ data }) => {
            let array = [];

            for (const element of data ) {
                array.push({ id: element.id, name: element.name_ua });
            }
            
            setFilters(array);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    return <SelectInput sx={{ width: '100%' }} label="Фільтр" source="filter_id" choices={filters} />;
};

export default FilterInput;