import { ValidationStrings } from "./validators";

export class ValidationError{
  constructor(message) {
    this.message = message;
    this.name = 'Error';
  }
}

export const stringNameValidation = (fieldName, fieldContent) => {
  let errors = [];
  if ( fieldContent === undefined || fieldContent === null || fieldContent.length === 0 || fieldContent === '') {
    errors.push(`Поле "${fieldName}" не має бути пустим`);
  }
  else if (!fieldContent.match(ValidationStrings.NAME)) {
    errors.push(`Поле "${fieldName}" має містити тільки букви`);
  }
  return errors.length !== 0 ? errors : null;
};

export const phoneValidation = (fieldName, fieldContent) => {
  let errors = [];
  if ( fieldContent === undefined || fieldContent === null || fieldContent.length === 0) {
    errors.push(`Поле "${fieldName}" не має бути пустим`);
  }
  else if (!fieldContent.match(/38-\d{10}/gm) ) {
    errors.push(`Поле "${fieldName}" має виглядати як номер телефона`);
  }
  return errors.length !== 0 ? errors : null;
};

export const emailValidation = (fieldName, fieldContent) => {
  let errors = [];
  if ( fieldContent === undefined || fieldContent === null || fieldContent.length === 0) {
    errors.push(`Поле "${fieldName}" не має бути пустим`);
  }
  if ( fieldContent && !fieldContent.match(ValidationStrings.EMAIL)) {
    errors.push('Введіть пошту у форматі hillsong@test.com');
  }
  return errors.length !== 0 ? errors : null;
};

export const passwordValidation = (fieldName, fieldContent) => {
  let errors = [];
  if ( fieldContent === undefined || fieldContent === null || fieldContent.length === 0) {
    errors.push(`Поле "${fieldName}" не має бути пустим`);
  }
  else if (fieldContent.length < 8) {
    errors.push('Ваш пароль має містити не менше 8 символів');
  }
  return errors.length !== 0 ? errors : null;
};
