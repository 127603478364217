import { Stack } from '@mui/material'
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import TablesField from '../../components/common/TablesField'

const postFilters = [
  <TextInput label='За назвою' source='name' />,
  <TextInput label='За адресою' source='address' />,
  <DateInput label='За датою зустрічі' source='date' />,
  <DateInput label='За датою оновлення' source='updated_date' />,
  <DateInput label='За датою створення' source='created_date' />,
]

const ListActions = () => (
  <TopToolbar>
    <FilterButton className='filter' filters={postFilters} />
    <CreateButton label='Створити' />
    <ExportButton label='Експорт' />
  </TopToolbar>
)

const TagFilter = () => (
  <Stack direction='row' justifyContent='space-between' alignItems='end'>
    <FilterForm filters={postFilters} />
  </Stack>
)
const RolesList = () => {
  return (
    <List
      title='Ролі'
      sx={{ tableLayout: 'fixed' }}
      actions={<ListActions />}
      filters={<TagFilter />}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons />}
        rowClick='edit'
        sx={{
          width: '100%',
          backgroundColor: '#f5f5f5',
        }}
      >
        <ReferenceField
          source='role_name_id'
          reference='roles_names'
          label='Назва Ролі'
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='table_id'
          reference='tables_names'
          label='Назва Таблиці'
        >
          <TablesField label={'Назва таблиці'} />
        </ReferenceField>
        <ReferenceField
          source='access_level_id'
          reference='access_level'
          label='Рівень доступу до контенту'
        >
          <TextField source='name' />
        </ReferenceField>
        <BooleanField source='is_default' label='По замовчуванню' />
      </Datagrid>
    </List>
  )
}

export default RolesList
