import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';

import Box from '@mui/material/Box';
import EditMarkerMap from '../../components/common/map/EditMarkerMap';
import { useFormContext } from 'react-hook-form';

const CustomToolBar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton label="Зберегти" />
  </Toolbar>
);

const MapWrapper = () => {
  const { coordinates } = useRecordContext();
  const { getValues } = useFormContext();

  const { name, address } = getValues();

  return <EditMarkerMap marker={coordinates} address={address} name={name} />;
};

const MeetingsEdit = () => {
  return (
    <Edit title="Редагувати зустріч" sx={{ tableLayout: 'fixed' }}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
          <MapWrapper />
          <Box display="flex" flexDirection="row" sx={{ width: '100%' }} gap={3} mt={2}>
            <TextInput label="Назва Укр" source="name" />
            <TextInput label="Адреса Укр" source="address" sx={{ width: '60%' }} />
          </Box>
          <Box display="flex" flexDirection="row" sx={{ width: '100%' }} gap={3}>
            <TextInput label="Назва Eng" source="name_en" />
            <TextInput label="Адреса Eng" source="address_en" sx={{ width: '60%' }} />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default MeetingsEdit;
