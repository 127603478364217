import { Chip } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

const AccessLevelField: FC<{ label: string }> = ({ label }) => {
  const { access_level } = useRecordContext();

  const [chipColor, setChipColor] = useState('linear-gradient(90deg, #22AC6A 0%, #13D578 100%)');
  const [chipText, setChipText] = useState('Користувач');

  useEffect(() => {
    switch (access_level) {
      case 'user':
        setChipText('Користувач');
        setChipColor('linear-gradient(90deg, #22AC6A 30%, #13D578 100%)');
        break;
      case 'coach':
        setChipText('Лідер');
        setChipColor('linear-gradient(90deg, #29BEDF 0%, #00A4FF 96.3%)');
        break;
      case 'moderator':
        setChipText('Модератор');
        setChipColor('#F8BA03');
        break;
      default:
        break;
    }
  }, [access_level]);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Chip
          label={chipText}
          sx={{
            background: chipColor,
            borderRadius: 2,
            color: 'white',
            fontWeight: 600,
          }}
        />
      )}
    />
  );
};

export default AccessLevelField;
