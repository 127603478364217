import {
    Edit,
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton,
    useRecordContext,
} from 'react-admin';

import Box from '@mui/material/Box';

const CustomToolBar = () => (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton label="Зберегти" />
    </Toolbar>
);
const RolesNamesEdit = () => {
    return (
        <Edit title="Редагувати назву ролі" sx={{ tableLayout: 'fixed' }}>
            <SimpleForm toolbar={<CustomToolBar />}>
                <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                    <TextInput label="Назва" source="name" />
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default RolesNamesEdit;
