import { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  required,
  Toolbar,
  Button,
  useDataProvider,
  useNotify,
  useCreate,
  useRedirect,
  LinearProgress,
  useFormGroupContext,
  SelectArrayInput,
} from 'react-admin';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form'

export const FilterInput = () => {
  const [filters, setFilters] = useState<any[]>([]);

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('filters', {
        pagination: {
          page: 1,
          perPage: 100000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        filter: undefined,
      })
      .then(({ data }) => {
        let array = [];

        for (const element of data) {
          array.push({ id: element.id, name: element.name_ua });
        }

        setFilters(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <SelectArrayInput label='Фільтри' source="filter_ids" choices={filters} />
};


const CustomToolbar = () => {
  const form = useFormContext();
  const notify = useNotify()
  const redirect = useRedirect()
  const [create, { isLoading }] = useCreate()

  const onSave = async () => {

    try {
      const values = form.getValues();

      const data = {
        name: values.name,
        filter_ids: values.filter_ids
      }

      if (!data.name) {
        notify('Введіть назву', { type: 'error' })
        return
      }

      await create('access_level', { data })
      redirect('/access_level')
    } catch (e) {
      console.log(e)
    }

  }

  return (
    <Toolbar>
      {
        isLoading ? <LinearProgress sx={{ width: '100%' }} /> :
          <Button label="Зберегти" disabled={isLoading} size="medium"
            onClick={onSave}
          />
      }
    </Toolbar>
  )
}

const AccessLevelCreate = () => {
  return <Create title="Створити новий рівень">
    <SimpleForm toolbar={<CustomToolbar />}>
      <Box display="flex" sx={{ gap: 3 }}>
        <Box >
          <TextInput label="Назва" source="name" validate={required()} />
        </Box>
        <Box >
          <FilterInput />
        </Box>
      </Box>
    </SimpleForm>

  </Create>
}

export default AccessLevelCreate;
