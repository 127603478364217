import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  UrlField,
  useDataProvider,
  useRecordContext,
  WithListContext,
} from 'react-admin'

const DescriptionField: FC<{ label: string }> = ({ label }) => {
  const { description } = useRecordContext()
  const [text, setText] = useState(
    description.length < 100 ? description : `${description.slice(0, 100)}...`
  )
  const [pressed, setPressed] = useState(false)

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display='flex' flexDirection='column'>
          <span> {text} </span>
          {description.length >= 100 && (
            <Box display='flex' alignItems='flex-start'>
              <Button
                sx={{ mt: 1 }}
                label={pressed ? 'Сховати' : 'Більше'}
                onClick={() => {
                  setPressed(!pressed)
                  setText(
                    pressed ? `${description.slice(0, 100)}...` : description
                  )
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}

const DescriptionENField: FC<{ label: string }> = ({ label }) => {
  const { description_en } = useRecordContext()
  const [text, setText] = useState(
    description_en.length < 100
      ? description_en
      : `${description_en.slice(0, 100)}...`
  )
  const [pressed, setPressed] = useState(false)

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display='flex' flexDirection='column'>
          <span> {text} </span>
          {description_en.length >= 100 && (
            <Box display='flex' alignItems='flex-start'>
              <Button
                sx={{ mt: 1 }}
                label={pressed ? 'Сховати' : 'Більше'}
                onClick={() => {
                  setPressed(!pressed)
                  setText(
                    pressed
                      ? `${description_en.slice(0, 100)}...`
                      : description_en
                  )
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}

const ParticipantCountField = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [participantCount, setParticipantCount] = useState(0)

  useEffect(() => {
    if (record && record.id) {
      dataProvider.getList('registered_participants', {
        filter: { eventId: record.id },
        pagination: {
          page: 1,
          perPage: 100000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
      }).then((data) => {
        setParticipantCount(data.data.length)
      })
    }
  }, [record, dataProvider])

  return <span>{participantCount}</span>
}

const EventsList = () => {
  return (
    <List
      title='Події'
      sx={{ tableLayout: 'fixed' }}
    // actions={<ListActions />}
    // filters={<TagFilter />}
    >
      <Datagrid
        // bulkActionButtons={<BulkActionButtons />}
        rowClick='edit'
        sx={{
          backgroundColor: '#f5f5f5',
        }}
      >
        <TextField label='Назва' source='name' />
        <TextField label='Назва EN' source='name_en' />

        <FunctionField label='К-ть учасників' render={() => <ParticipantCountField />} />

        <DescriptionField label='Опис' />
        <DescriptionENField label='Опис EN' />

        <UrlField label='Лінк' source='link' />

        <BooleanField
          label='Показується'
          source='shown'
          valueLabelTrue='Так'
          valueLabelFalse='Ні'
        />

        <DateField label='Дата проведення' source='date' />

        <ImageField label='Фото' source='cover' />
        <ImageField label='Фото EN' source='cover_en' />
      </Datagrid>
    </List>
  )
}

export default EventsList
