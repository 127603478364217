import { FC, useRef, useState } from 'react'
import {
	Button,
	Create,
	ImageField,
	ImageInput,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreate,
	useNotify,
	useRedirect,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { Alert, Button as MuiButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

import { styled } from '@mui/material/styles'
import { download, storageRef, UploadVideoSnippet } from '../../lib/firebase'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 400,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))

export const uploadAndDownloadBlob = async (blob: Blob, name: string) => {
	const snapshot = await UploadVideoSnippet(name.replace(/\s/g, ''), blob);
	return await download(storageRef(snapshot.metadata.fullPath));
 };
 

const CustomToolBar: FC<{
	logoSrc: string
	logoSrcEN: string
	cover: string
	coverEN: string
	logoBlob: Blob | null
	logoBlobEN: Blob | null
	blob: Blob | null
	blob_en: Blob | null
}> = ({
	logoSrc,
	logoSrcEN,
	cover,
	coverEN,
	logoBlob,
	logoBlobEN,
	blob,
	blob_en,
}) => {
	const form = useFormContext()
	const notify = useNotify()
	const [create, { isLoading }] = useCreate()

	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const values = form.getValues()

			let logo = logoSrc || ''
			let logoEN = logoSrcEN || ''

			let snippet = cover || ''
			let snippetEN = coverEN || ''

			if (logoBlob) {
				const snapshot = await UploadVideoSnippet(
					values.name.replace(/\s/g, ''),
					logoBlob
				)
				logo = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (logoBlobEN) {
				const snapshot = await UploadVideoSnippet(
					values.name_en.replace(/\s/g, ''),
					logoBlobEN
				)
				logoEN = await download(storageRef(snapshot.metadata.fullPath))
			}

			console.log("other blob", blob)

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					values.description.replace(/\s/g, ''),
					blob
				)
				snippet = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (blob_en) {
				const snapshot = await UploadVideoSnippet(
					values.description_en.replace(/\s/g, ''),
					blob_en
				)
				snippetEN = await download(storageRef(snapshot.metadata.fullPath))
			}


			const blobArray = values.pictures.filter((picture: {rawFile: File, src: string, title: string}) => picture.rawFile)
			.map((picture: {rawFile: File, src: string, title: string}) => picture.rawFile);

			let covers = [];

			for (let i = 0; i < blobArray.length; i++) {
				const cover = await uploadAndDownloadBlob(blobArray[i], blobArray[i].path);
				covers.push(cover);
			}


			if (!values.name) return;

			const data = {
				name: values.name,
				name_en: values.name_en,
				description: values.description,
				description_en: values.description_en,
				cover_small: logo,
				cover_small_en: logoEN,
				cover_large: snippet,
				cover_large_en: snippetEN,
				covers_description: covers
			}


			await create('volunteer_teams', { data })
			redirect('/volunteer_teams')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const VolunteerTeamsCreate = () => {
	const notify = useNotify()
	const [logoSrc, setLogoSrc] = useState('')
	const [logoSrcEN, setLogoSrcEN] = useState('')
	const [cover, setCover] = useState('')
	const [coverEN, setCoverEN] = useState('')

	const [logoBlob, setLogoBlob] = useState<Blob | null>(null)
	const [logoBlobEN, setLogoBlobEN] = useState<Blob | null>(null)

	const [blob, setBlob] = useState<Blob | null>(null)
	const [blob_en, setBlobEN] = useState<Blob | null>(null)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)
	const ImageInputRefEN = useRef<HTMLInputElement>(null)

	const LogoInputRef = useRef<HTMLInputElement>(null)
	const LogoInputRefEN = useRef<HTMLInputElement>(null)

	const handleUploadLogo = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img
			setLogoSrc(img.src)
			setLogoBlob(event.target.files[0])
		}
	}

	const handleUploadLogoEN = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {

			setLogoSrcEN(img.src)
			setLogoBlobEN(event.target.files[0])
		}
	}

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img
			setCover(img.src)
			setBlob(event.target.files[0])
			setBlobError('')
		}
	}

	const handleUploadEN = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img
			
			setCoverEN(img.src)
			setBlobEN(event.target.files[0])
			setBlobError('')
				
		}
	}

	console.log('blob', blob)
	return (
		<Create title='Створити нову команду' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm
				toolbar={
					<CustomToolBar
						logoSrc={logoSrc ?? null}
						logoSrcEN={logoSrcEN}
						cover={cover}
						coverEN={coverEN}
						logoBlob={logoBlob}
						logoBlobEN={logoBlobEN}
						blob={blob}
						blob_en={blob_en}
					/>
				}
			>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Українською'
						source='name'
					/>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Англійською'
						source='name_en'
					/>
				</Box>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Українською'
						source='description'
					/>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Англійською'
						source='description_en'
					/>
				</Box>

				{/*Logos*/}
				<Box
					display='flex'
					flex='row'
					width='100%'
					sx={{ mt: 3 }}
					alignItems='center'
					gap={3}
				>
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={LogoInputRef}
							onChange={handleUploadLogo}
						/>
						<img
							style={{ width: '100%', height: '100%' }}
							src={logoBlob ? URL.createObjectURL(logoBlob) : ''}
							alt='photo'
						/>

						{blobError && (
							<Alert severity='error' sx={{ mb: 2 }}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (LogoInputRef.current) {
										LogoInputRef.current.click()
									}
								}}
							>
								Змінити лого
							</MuiButton>
						</Box>
					</Box>

					{/*Logo EN*/}
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={LogoInputRefEN}
							onChange={handleUploadLogoEN}
						/>
						<img
							style={{ width: '100%', height: '100%' }}
							src={logoBlobEN ? URL.createObjectURL(logoBlobEN) : ''}
							alt='photo'
						/>

						{blobError && (
							<Alert severity='error' sx={{ mb: 2 }}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								fullWidth
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (LogoInputRefEN.current) {
										LogoInputRefEN.current.click()
									}
								}}
							>
								Змінити лого EN
							</MuiButton>
							
						</Box>
					</Box>
				</Box>

				<ImageInput source="pictures" accept="image/*" multiple label="Related pictures">
					<ImageField source="src"  title="title" />
				</ImageInput>
				<Box>
					<Typography
						sx={{
							fontSize: 14,
						}}
					>
						Зображення повинно мати 534x356	пікселів
					</Typography>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default VolunteerTeamsCreate
