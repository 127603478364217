import { useContext, useEffect, useState } from "react";
import {
  BooleanInput,
  Button,
  DateInput,
  DeleteButton,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
  useRecordContext,
  useRedirect,
  useSaveContext,
  useUpdate,
} from "react-admin";

import Box from "@mui/material/Box";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useFormContext } from "react-hook-form";
import CoachInput from "../../components/common/inputs/CoachInput";
import CountryInput from "../../components/common/inputs/CountryInput";
import { AccessContext } from "../../containers/access-provider";
import { useCollectionQuery } from "../../hooks/useCollectionQuery";
import { db, fns } from "../../lib/firebase";

const UserTitle = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomSaveButton = () => {
  const [saveLoading, setSaveLoading] = useState(null);

  const [update, { isLoading }] = useUpdate();
  const saveContext = useSaveContext();
  const {
    id: user_id,
    banned,
    connect_id,
    coach_connect_id,
    coach_id,
    access_level,
  } = useRecordContext();
  const form = useFormContext();
  const redirect = useRedirect();

  const [connectGroupList, setConnectGroupList] = useState([]);
  const dataProvider = useDataProvider();

  // Дістаємо всі чати де юзер є у списку
  const { data, error } = useCollectionQuery(
    "conversations",
    query(
      collection(db, "conversations"),
      orderBy("updatedAt", "desc"),
      where("users", "array-contains", user_id)
    )
  );

  // Дістаємо всі групи в зручному форматі
  const chats = data?.docs.map((item) => ({
    id: item.id,
    ...item.data(),
  }));

  console.log("chats", chats);

  useEffect(() => {
    dataProvider
      .getList("connect_group_list", {})
      .then(({ data }) => {
        const array = [];
        console.log("data", data);

        for (const element of data) {
          array.push({
            id: element.id,
            name: element.name,
            coachId: element.main_coach_id,
          });
        }

        setConnectGroupList(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const SaveHandler = async () => {
    setSaveLoading(true);
    const values = form.getValues();

    console.log("values", values);

    if (values.access_level !== access_level) {
      if (coach_connect_id) {
        const groupDocRef = doc(db, "conversations", coach_connect_id);
        const groupDocSnap = await getDoc(groupDocRef);
        await updateDoc(groupDocSnap, {
          coach: { id: null },
          group: {
            coachId: null,
            groupId: coach_connect_id,
          },
          users: arrayRemove(user_id), // remove user_id
          updatedAt: serverTimestamp(),
        });
      }

      // Встановлюємо isVisible: false, всім іншим чатам з лідером якщо лідер змінив групу
      chats.map(async (chat) => {
        const userDocRef = doc(db, "conversations", chat.id);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists() && chat.id !== coach_connect_id) {
          await updateDoc(userDocRef, {
            isVisible: false,
            updatedAt: serverTimestamp(),
          });
        }
      });
    }

    if (connect_id !== values.connect_id) {
      // Видаляємо юзера зі старої групи
      chats.map(async ({ id }) => {
        // Видаляємо з групи
        if (id === connect_id) {
          await updateDoc(doc(db, "conversations", connect_id), {
            users: arrayRemove(user_id),
            updatedAt: serverTimestamp(),
          });
        }

        // Видаляємо всі чати з юзерами
        if (id !== connect_id) {
          await updateDoc(doc(db, "conversations", id), {
            // users: [],
            isVisible: false,
            updatedAt: serverTimestamp(),
          });
        }
      });

      // Якщо юзер змінив групу, добавляємо в групу
      if (values.connect_id) {
        const groupDocRef = doc(db, "conversations", values.connect_id);
        const groupDocSnap = await getDoc(groupDocRef);

        if (groupDocSnap.exists()) {
          await updateDoc(groupDocRef, {
            users: arrayUnion(user_id),
            updatedAt: serverTimestamp(),
          });
        }
      }

      // Якщо юзеру видалили групу
      if (values.connect_id === null) {
        chats.map(async ({ id }) => {
          // Видаляємо з групи
          if (id === connect_id) {
            await updateDoc(doc(db, "conversations", connect_id), {
              users: arrayRemove(user_id),
              updatedAt: serverTimestamp(),
            });
          }

          // Видаляємо всі чати з юзерами
          if (id !== connect_id) {
            await updateDoc(doc(db, "conversations", id), {
              // users: [],
              isVisible: false,
              updatedAt: serverTimestamp(),
            });
          }
        });
      }
    }

    if (banned !== values.banned) {
      const banUser = httpsCallable(fns, "banUser");
      const unbanUser = httpsCallable(fns, "unbanUser");
      values.banned
        ? await banUser({ uid: user_id })
        : await unbanUser({ uid: user_id });
    }

    await update("users", { id: user_id, data: { ...values } });
    setSaveLoading(false);
    redirect("/users");
  };

  return (
    <Button
      label="Зберегти"
      disabled={saveContext?.saving || isLoading || saveLoading}
      size="medium"
      onClick={SaveHandler}
    />
  );
};

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <CustomSaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const RoleInput = () => {
  const [roles, setRoles] = useState([]);
  const { accesses } = useContext(AccessContext);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("roles_names", {})
      .then(({ data }) => {
        const array = [];

        for (const element of data) {
          array.push({ id: element.id, name: element.name });
        }

        setRoles(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return accesses["roles"] || accesses["roles_names"] || accesses["all"] ? (
    <SelectInput sx={{ ml: 5 }} label="Роль" source="role_id" choices={roles} />
  ) : (
    <></>
  );
};

const ConnectGroupInput = () => {
  const [connectGroups, setConnectGroups] = useState([]);

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("connect_group_list", {})
      .then(({ data }) => {
        const array = [];

        for (const element of data) {
          array.push({ id: element.id, name: element.name });
        }

        setConnectGroups(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <SelectInput
      sx={{ ml: 5 }}
      label="Конект група"
      source="connect_id"
      choices={connectGroups}
    />
  );
};

const UsersEdit = () => {
  return (
    <Edit title={<UserTitle />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" sx={{ ml: 3 }}>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ mb: 5 }} disabled label="ID" source="id" />
            <TextInput sx={{ ml: 5 }} disabled label="Пошта" source="email" />
            <DateInput
              sx={{ ml: 10 }}
              disabled
              label="Зареєстрован"
              source="created_at"
            />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput label="ПІБ" source="name" validate={required()} />
            <TextInput sx={{ ml: 5 }} label="Церква" source="church" />
            <CoachInput />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput label="Телефон" source="phone" />
            <SelectInput
              sx={{ ml: 5 }}
              label="Стать"
              source="gender"
              emptyText="Бажаю не вказувати"
              choices={[
                { id: "male", name: "Чоловік" },
                { id: "female", name: "Жінка" },
              ]}
            />
            <ConnectGroupInput />
            <RoleInput />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <CountryInput />
            <TextInput sx={{ ml: 5 }} label="Місто" source="region" />
            <SelectInput
              sx={{ ml: 5 }}
              validate={required()}
              label="Рівень доступу"
              source="access_level"
              choices={[
                { id: "user", name: "Користувач" },
                { id: "coach", name: "Лідер" },
                { id: "moderator", name: "Модератор" },
              ]}
            />
            <BooleanInput
              sx={{ ml: 5, mt: 2 }}
              label="Забанено"
              source="banned"
            />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
