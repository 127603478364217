import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useListContext, useUpdateMany, useUnselectAll } from 'react-admin';

const NormalizeStatus: FC<{ status: string }> = ({ status }) => {
  if (status === 'accepted') {
    return <> Прийняти </>;
  } else if (status === 'rejected') {
    return <> Відхилити </>;
  } else return <> Очікувати </>;
};

const UpdateStatusButton: FC<{ status: string; resource: string }> = ({ status, resource}) => {
  const { selectedIds } = useListContext();
  const [updateMany, { isLoading, error }] = useUpdateMany();
  const unselect = useUnselectAll(resource);

  const clickHandler = async () => {
    await updateMany(resource, {
      ids: selectedIds,
      data: { status },
    });
    unselect();
  };

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Button size="medium" onClick={clickHandler} disabled={isLoading}>
      {isLoading ? <CircularProgress size={20} /> : <NormalizeStatus status={status} />}
    </Button>
  );
};

export default UpdateStatusButton;
