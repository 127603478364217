import { buildFields } from 'ra-data-hasura'
import { GET_LIST, GET_MANY, GET_ONE, UPDATE, UPDATE_MANY } from 'react-admin'

import {
	GET_LIST_ACCESS_LEVEL,
	GET_LIST_CONNECT_GROUP,
	GET_LIST_CONNECT_GROUP_LIST,
	GET_LIST_EVENTS,
	GET_LIST_FILTER,
	GET_LIST_FOUNDATIONS,
	GET_LIST_MEETING,
	GET_LIST_MEETING_REQUESTS,
	GET_LIST_PRAYER,
	GET_LIST_ROLES,
	GET_LIST_USER,
	GET_LIST_VIDEO,
	GET_LIST_VOLONTIER_GROUP,
	GET_LIST_VOLUNTEER_TEAMS,
	GET_ONE_ACCESS_LEVEL,
	GET_ONE_CONNECT_GROUP,
	GET_ONE_CONNECT_GROUP_LIST,
	GET_ONE_EVENT,
	GET_ONE_FILTER,
	GET_ONE_FOUNDATION,
	GET_ONE_MEETING,
	GET_ONE_MEETING_REQUESTS,
	GET_ONE_PRAYER,
	GET_ONE_ROLES,
	GET_ONE_USER,
	GET_ONE_VIDEO,
	GET_ONE_VOLONTIER_GROUP,
	GET_ONE_VOLUNTEER_TEAMS,
} from '../lib/raQueries'

const extractFieldsFromQuery = queryAst =>
	queryAst.definitions[0].selectionSet.selections

const CUSTOM_QUERIES = {
	users: {
		[GET_LIST]: GET_LIST_USER,
		[GET_ONE]: GET_ONE_USER,
		[UPDATE]: GET_ONE_USER,
		[GET_ONE]: GET_ONE_USER,
		[GET_MANY]: GET_LIST_USER,
		[UPDATE_MANY]: GET_LIST_USER,
	},
	events: {
		[GET_LIST]: GET_LIST_EVENTS,
		[GET_ONE]: GET_ONE_EVENT,
		[UPDATE]: GET_ONE_EVENT,
		[GET_ONE]: GET_ONE_EVENT,
		[GET_MANY]: GET_LIST_EVENTS,
		[UPDATE_MANY]: GET_LIST_EVENTS,
	},
	foundations: {
		[GET_LIST]: GET_LIST_FOUNDATIONS,
		[GET_ONE]: GET_ONE_FOUNDATION,
		[UPDATE]: GET_ONE_FOUNDATION,
		[GET_ONE]: GET_ONE_FOUNDATION,
		[GET_MANY]: GET_LIST_FOUNDATIONS,
		[UPDATE_MANY]: GET_LIST_FOUNDATIONS,
	},
	videos: {
		[GET_LIST]: GET_LIST_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[UPDATE]: GET_ONE_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[GET_MANY]: GET_LIST_VIDEO,
		[UPDATE_MANY]: GET_LIST_VIDEO,
	},
	analytics: {
		[GET_LIST]: GET_LIST_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[UPDATE]: GET_ONE_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[GET_MANY]: GET_LIST_VIDEO,
		[UPDATE_MANY]: GET_LIST_VIDEO,
	},
	volontier_group: {
		[GET_LIST]: GET_LIST_VOLONTIER_GROUP,
		[GET_ONE]: GET_ONE_VOLONTIER_GROUP,
		[UPDATE]: GET_ONE_VOLONTIER_GROUP,
		[GET_ONE]: GET_ONE_VOLONTIER_GROUP,
		[GET_MANY]: GET_LIST_VOLONTIER_GROUP,
		[UPDATE_MANY]: GET_LIST_VOLONTIER_GROUP,
	},
	connect_group: {
		[GET_LIST]: GET_LIST_CONNECT_GROUP,
		[GET_ONE]: GET_ONE_CONNECT_GROUP,
		[UPDATE]: GET_ONE_CONNECT_GROUP,
		[GET_ONE]: GET_ONE_CONNECT_GROUP,
		[GET_MANY]: GET_LIST_CONNECT_GROUP,
		[UPDATE_MANY]: GET_LIST_CONNECT_GROUP,
	},
	connect_group_list: {
		[GET_LIST]: GET_LIST_CONNECT_GROUP_LIST,
		[GET_ONE]: GET_ONE_CONNECT_GROUP_LIST,
		[UPDATE]: GET_ONE_CONNECT_GROUP_LIST,
		[GET_ONE]: GET_ONE_CONNECT_GROUP_LIST,
		[GET_MANY]: GET_LIST_CONNECT_GROUP_LIST,
		[UPDATE_MANY]: GET_LIST_CONNECT_GROUP_LIST,
	},
	filters: {
		[GET_LIST]: GET_LIST_FILTER,
		[GET_ONE]: GET_ONE_FILTER,
		[UPDATE]: GET_ONE_FILTER,
		[GET_ONE]: GET_ONE_FILTER,
		[GET_MANY]: GET_LIST_FILTER,
		[UPDATE_MANY]: GET_LIST_FILTER,
	},
	meetings: {
		[GET_LIST]: GET_LIST_MEETING,
		[GET_ONE]: GET_ONE_MEETING,
		[UPDATE]: GET_ONE_MEETING,
		[GET_ONE]: GET_ONE_MEETING,
		[GET_MANY]: GET_LIST_USER,
		[UPDATE_MANY]: GET_LIST_MEETING,
	},
	meetings_requests: {
		[GET_LIST]: GET_LIST_MEETING_REQUESTS,
		[GET_ONE]: GET_ONE_MEETING_REQUESTS,
		[UPDATE]: GET_ONE_MEETING_REQUESTS,
		[GET_ONE]: GET_ONE_MEETING_REQUESTS,
		[GET_MANY]: GET_LIST_MEETING_REQUESTS,
		[UPDATE_MANY]: GET_LIST_MEETING_REQUESTS,
	},
	prayers: {
		[GET_LIST]: GET_LIST_PRAYER,
		[GET_ONE]: GET_ONE_PRAYER,
		[UPDATE]: GET_ONE_PRAYER,
		[GET_ONE]: GET_ONE_PRAYER,
		[GET_MANY]: GET_LIST_PRAYER,
		[UPDATE_MANY]: GET_LIST_PRAYER,
	},
	volunteerTeams: {
		[GET_LIST]: GET_LIST_VOLUNTEER_TEAMS,
		[GET_ONE]: GET_ONE_VOLUNTEER_TEAMS,
		[UPDATE]: GET_ONE_VOLUNTEER_TEAMS,
		[GET_ONE]: GET_ONE_VOLUNTEER_TEAMS,
		[GET_MANY]: GET_LIST_VOLUNTEER_TEAMS,
		[UPDATE_MANY]: GET_LIST_VOLUNTEER_TEAMS,
	},
	access_level: {
		[GET_LIST]: GET_LIST_ACCESS_LEVEL,
		[GET_ONE]: GET_ONE_ACCESS_LEVEL,
		[UPDATE]: GET_ONE_ACCESS_LEVEL,
		[GET_ONE]: GET_ONE_ACCESS_LEVEL,
		[GET_MANY]: GET_LIST_ACCESS_LEVEL,
		[UPDATE_MANY]: GET_LIST_ACCESS_LEVEL,
	},
	roles: {
		[GET_LIST]: GET_LIST_ROLES,
		[GET_ONE]: GET_ONE_ROLES,
		[UPDATE]: GET_ONE_ROLES,
		[GET_ONE]: GET_ONE_ROLES,
		[GET_MANY]: GET_LIST_ROLES,
		[UPDATE_MANY]: GET_LIST_ROLES,
	},
}

export const customBuildFields = (type, fetchType) => {
	const resourceName = type.name

	// console.log('resourceName', resourceName)

	const resourceCustomQueries = CUSTOM_QUERIES[resourceName]

	// console.log('resourceCustomQueries', resourceCustomQueries)
	// console.log("resourceCustomQueries[fetchType]", resourceCustomQueries[fetchType])

	if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
		return extractFieldsFromQuery(resourceCustomQueries[fetchType])
	}

	return buildFields(type, fetchType)
}

// const hasuraDataProvider = async (type, resource, params) => {
//   const provider = await buildDataProvider({ client: apolloClient }, { buildFields: customBuildFields });
//   return provider(type, resource, params);
// };

// const generalDataProvider = (type, resource, params) => hasuraDataProvider(type, resource, params);

// export default generalDataProvider;
