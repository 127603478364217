import { Box, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
    BooleanField,
    BooleanInput,
    BulkDeleteButton,
    Button,
    Datagrid,
    DateField,
    DateTimeInput,
    ExportButton,
    FilterButton,
    FilterForm,
    FunctionField,
    List,
    NumberInput,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    required,
    useRecordContext,
} from 'react-admin'

import { useLocation } from 'react-router-dom'
import PrayerField from '../../components/common/PrayerField'
import PreferenceButtons from '../../components/common/PreferenceButtons'

const getWeekFilter = (value: string): string => {
    const today = new Date()

    if (value === '1') {
        const lastWeek = new Date()
        lastWeek.setDate(today.getDate() - 7)
        return lastWeek.toISOString()
    } else if (value === '2') {
        const twoWeeksAgo = new Date()
        twoWeeksAgo.setDate(today.getDate() - 14)
        return twoWeeksAgo.toISOString()
    } else if (value === '3') {
        const threeWeeksAgo = new Date()
        threeWeeksAgo.setDate(today.getDate() - 21)
        return threeWeeksAgo.toISOString()
    } else if (value === '4') {
        const threeWeeksAgo = new Date()
        threeWeeksAgo.setDate(today.getDate() - 28)
        return threeWeeksAgo.toISOString()
    } else if (value === '5') {
        const lessWeekAgo = new Date()
        lessWeekAgo.setDate(today.getDate() - 7)
        return lessWeekAgo.toISOString()
    }

    return ''
}


const getNewPraysFilter = (value: string): string => {

    if (value === '1') {
        let today = new Date()
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        return today.toISOString()
    } else if (value === '2') {
        let twoWeeksAgo = new Date()
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 1)
        twoWeeksAgo = new Date(Date.UTC(twoWeeksAgo.getFullYear(), twoWeeksAgo.getMonth(), twoWeeksAgo.getDate()));

        return twoWeeksAgo.toISOString()
    } else if (value === '3') {
        let threeWeeksAgo = new Date()
        threeWeeksAgo.setDate(threeWeeksAgo.getDate() - 2)
        threeWeeksAgo = new Date(Date.UTC(threeWeeksAgo.getFullYear(), threeWeeksAgo.getMonth(), threeWeeksAgo.getDate()));

        return threeWeeksAgo.toISOString()
    } else if (value === '4') {
        let threeWeeksAgo = new Date()
        threeWeeksAgo.setDate(threeWeeksAgo.getDate() - 6)
        threeWeeksAgo = new Date(Date.UTC(threeWeeksAgo.getFullYear(), threeWeeksAgo.getMonth(), threeWeeksAgo.getDate()));

        return threeWeeksAgo.toISOString()
    }

    return ''
}

const weekOptions = [
    {id: getWeekFilter('1'), name: 'Більше тижня тому'},
    {id: getWeekFilter('2'), name: 'Більше 2 тижнів тому'},
    {id: getWeekFilter('3'), name: 'Більше 3 тижнів тому'},
    // { id: getWeekFilter('5'), name: 'До 1 тиждня' },
]

const newPrayers = [
    {id: getNewPraysFilter('1'), name: `Сьогодні`},
    {id: getNewPraysFilter('2'), name: `Останні 2 дні`},
    {id: getNewPraysFilter('3'), name: `Останні 3 дні`},
    {id: getNewPraysFilter('4'), name: `Останні 7 днів`},
]

const getPrayerOption = (value: string) => {
    const oneMonthAgo = new Date()
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

    if (value === 'archive') {
        return oneMonthAgo.toISOString()
    }

    return ''
}

const postFilters = [
    <SelectInput
        label='За типом'
        source='type'
        validate={required()}
        choices={[
            {id: 'prayer', name: 'Молитва'},
            {id: 'praise', name: 'Свідотство'},
        ]}
    />,
    <TextInput label='За текстом' source='text'/>,
    <NumberInput label='За лайками' source='press_count'/>,
    <BooleanInput label='За анонімністю' source='isAnon' sx={{ml: 2, mb: 1}}/>,
    <DateTimeInput label='За датою віправлення' source='created_at'/>,

    <BooleanInput
        label='Архівні (більше місяця тому)'
        source='is_archived'
        sx={{ml: 2, mb: 1}}
    />,
    <SelectInput
        source='created_at@_gte'
        label='На цьому тижні'
        choices={newPrayers}
        filter={getNewPraysFilter('1')}
    />,
    <SelectInput
        source='created_at@_lte'
        label='За останні тижні'
        choices={weekOptions}
        filter={getWeekFilter('1')}
    />,
]

const ListActions = () => (
    <TopToolbar>
        <FilterButton className='filter' filters={postFilters}/>
        <ExportButton label='Експорт'/>
    </TopToolbar>
)

const TagFilter = () => (
    <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <FilterForm filters={postFilters}/>
    </Stack>
)

const BulkActionButtons = () => {
    return (
        <Box mb={1}>
            <BulkDeleteButton label='Видалити'/>
        </Box>
    )
}

const DescriptionField: FC<{ label: string }> = ({label}) => {
    const {text: description} = useRecordContext()
    const max_size = 100

    const [text, setText] = useState(
        description && description.length > 0 && description.length < max_size
            ? description
            : `${description?.slice(0, max_size)}...` || ""
    )
    const [pressed, setPressed] = useState(false)

    return (
        <FunctionField
            label={label}
            render={() => (
                <Box display='flex' flexDirection='column'>
                    <span> { text ? text : ""} </span>
                    {description && description.length < max_size ? (
                        <></>
                    ) : (
                        <Box display='flex' alignItems='flex-start'>
                            <Button
                                sx={{mt: 1}}
                                label={pressed ? 'Сховати' : 'Більше'}
                                onClick={() => {
                                    setPressed(!pressed)
                                    setText(
                                        pressed
                                            ? `${description.slice(0, max_size)}...`
                                            : description
                                    )
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        />
    )
}

const SenderComponent: FC<{ label: string }> = ({label}) =>{
    const {name, email, phone, user} = useRecordContext();


    return (
        <FunctionField
            label={label}
            render={() => (
                <Box display='flex' flexDirection='column'>
                    {
                        name.trim() && name.length > 0
                            ? <span> {name} </span>
                            : <span> {email.trim() && email.length > 0 ? email : phone} </span>
                    }
                </Box>
            )}
        />
    )
}

const oneMonthAgo = new Date()
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

const PrayerWallList = () => {
    const location = useLocation()
    const [isArchived2, setIsArchived2] = useState<boolean | null>(null)

    useEffect(() => {
        // Get the "filter" parameter from the current location
        const filterParam = new URLSearchParams(location.search).get('filter')

        if (filterParam) {
            // Parse the "filter" parameter as JSON
            const filterObject = JSON.parse(filterParam)

            setIsArchived2(filterObject.is_archived)
        }
    }, [location])

    return (
        <List
            title='Молитовна стіна'
            sx={{tableLayout: 'fixed'}}
            actions={<ListActions/>}
            filters={<TagFilter/>}
            sort={{field: 'created_at', order: 'ASC'}}
            filter={{
                is_archived: isArchived2 ? true : false,
            }}
        >
            <Datagrid
                bulkActionButtons={<BulkActionButtons/>}
                sx={{
                    width: '100%',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <PrayerField label='Тип'/>

                <DescriptionField label='Текст'/>

                <ReferenceField label='Відправник' source='user_id' reference='users'>
                    <SenderComponent label="Відправник"/>
                </ReferenceField>


                <BooleanField
                    label='Анонимність'
                    source='isAnon'
                    valueLabelTrue='Так'
                    valueLabelFalse='Ні'
                />
                {/* <BooleanField
					label='Архів'
					source='is_archived'
					valueLabelTrue='Так'
					valueLabelFalse='Ні'
				/> */}

                <TextField label='Лайки' source='press_count'/>

                <DateField label='Відправлено' source='created_at' showTime/>

                <PreferenceButtons label='Налаштування'/>
            </Datagrid>
        </List>
    )
}

export default PrayerWallList
