import { FireplaceTwoTone } from '@mui/icons-material'
import {
  CreateButton,
  Datagrid,
  DateField,
  EmailField,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  required,
  ReferenceArrayField,
} from 'react-admin'


const AccessLevelList = () => {
  return <List
    title='Рівні доступу'
    sx={{ tableLayout: 'fixed' }}
  // actions={<ListActions />}
  // filters={<TagFilter />}
  >
    <Datagrid
      // bulkActionButtons={<BulkActionButtons />}
      rowClick='edit'
      sx={{
        backgroundColor: '#f5f5f5',
      }}
    >
      <TextField label='Рівень доступа до контенту' source='name' />

      <ReferenceArrayField label='Фільтри' reference='filters' source='filter_ids' />
      <DateField label='Дата створення' source='created_at' />,
    </Datagrid>
  </List>
}

export default AccessLevelList;
