import { Stack } from '@mui/material'
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  FormTab,
  ImageField,
  List,
  NumberInput,
  ReferenceManyField,
  TabbedForm,
  TabbedFormTabs,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'

const postFilters = [
  <TextInput label='За назвою' source='name_ua' />,
  <TextInput label='За типом' source='type' />,
  <NumberInput label='За порядком' source='index' />,
  <BooleanInput
    label='За показом іконки'
    source='is_icon_shown'
    sx={{ ml: 2, mb: 1 }}
  />,
  <DateInput label='За датою створення' source='created_at' />,
]

const ListActions = () => (
  <TopToolbar>
    <FilterButton className='filter' filters={postFilters} />
    <CreateButton label='Створити' />
    <ExportButton label='Експорт' />
  </TopToolbar>
)

const TagFilter = () => (
  <Stack direction='row' justifyContent='space-between' alignItems='end'>
    <FilterForm filters={postFilters} />
  </Stack>
)
const FiltersList = () => {
  return (
    <List
      title='Фільтри'
      sx={{ tableLayout: 'fixed' }}
      actions={<ListActions />}
      filters={<TagFilter />}
    >
      <TabbedForm
        tabs={<TabbedFormTabs variant='scrollable' scrollButtons='auto' />}
        syncWithLocation={false}
      >
        <FormTab label='Фільтри'>
          <Datagrid
            rowClick='edit'
            bulkActionButtons={<BulkActionButtons />}
            sx={{
              width: '100%',
              backgroundColor: '#f5f5f5',
            }}
          >
            <TextField label='Тип' source='type' />
            <TextField label='Назва' source='name_ua' />
            <ImageField
              label='Іконка'
              source='filter_icon'
              sx={{
                backgroundColor: '#dedede',
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .RaImageField-image': { height: 40 },
              }}
            />
            <TextField label='Позиція' source='position' />

            <BooleanField
              label='Показувати іконку'
              source='is_icon_shown'
              valueLabelFalse='Ні'
              valueLabelTrue='Так'
            />
            <BooleanField
              label='Доступно для незареєстрованих користувачів'
              valueLabelFalse='Ні'
              valueLabelTrue='Так' source="is_access_unregister_user" />

            {/*<NumberField label="Порядок" source="index" />*/}

            <DateField label='Дата створення' source='created_at' />
          </Datagrid>
        </FormTab>
        <FormTab label='Записи за фільтрами'>
          <Datagrid bulkActionButtons={false}>
            <TextField label='Тип' source='type' />
            <TextField label='Назва' source='name_ua' />
            <ReferenceManyField
              label='Відео'
              reference='videos'
              target='filter_id'
            >
              <Datagrid bulkActionButtons={false}>
                <ImageField label='Фото' source='snippet' />
                <TextField label='Назва' source='title' />
                <UrlField label='URL' source='url' />
              </Datagrid>
            </ReferenceManyField>
          </Datagrid>
        </FormTab>
      </TabbedForm>
    </List>
  )
}

export default FiltersList
